import { JsonDevTools } from '../../../components/JsonDevTools';
import { InvoicesDetailPanel } from '../InvoicesTable/InvoicesDetailPanel.graphql';
import { PartnerCreditsTable } from '../PartnerCreditsTable.graphql';
import { PartnerContractsTable } from '../PracticeContracts/PracticeContractsTable.graphql';
import type { BillingDetailsProps } from '../types';
import { OrganizationOverview, OVERVIEW_CONTAINER_GAP, OVERVIEW_CONTAINER_WIDTH } from './OrganizationOverview';
import { useBillingDetailsContext } from './providers/BillingDetailsProvider.graphql';
import { ArrowEastIcon, ArrowWestIcon, IconButton, Text, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const CONTRACTS_AND_CREDITS_WIDTH = 530;
const CARD_WIDTH = OVERVIEW_CONTAINER_WIDTH + OVERVIEW_CONTAINER_GAP;

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '40px',
    height: '100%',
});

const OverviewContainer = styled('div', { shouldForwardProp: p => p !== 'idx' })<{ idx: number }>(({ idx }) => ({
    width: '100%',
    height: '596px',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'row',
    marginBottom: '16px',
    transition: 'transform 0.5s ease-in-out',
    transform: `translateX(-${idx * CARD_WIDTH}px)`,
}));

const LeftContainer = styled('div')({
    width: `calc(100% - ${CONTRACTS_AND_CREDITS_WIDTH}px)`,
    overflowX: 'auto',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '24px',
    position: 'relative',
});

const PaginationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginBottom: '16px',
});

const ContractsAndCreditsContainer = styled('div')({
    width: `${CONTRACTS_AND_CREDITS_WIDTH}px`,
    flexShrink: 0,
    marginLeft: 'auto',
    overflowY: 'scroll',
});

const CreditsContainer = styled('div')({
    marginTop: '12px',
});

export const ExpansionPanelStyle: React.CSSProperties = {
    borderRadius: '16px',
    padding: '24px',
};

export const ExpansionPanelSummaryStyle: React.CSSProperties = {
    height: '24px',
    minHeight: '24px',
    padding: '0px',
};

export const ExpansionPanelDetailsStyle: React.CSSProperties = {
    marginTop: '24px',
    padding: '0px',
};

const TitleText = styled(Text)({
    marginBottom: '24px',
});

export const BillingDetailPanel: React.FC<BillingDetailsProps> = ({ practiceId }) => {
    const { value: enableMultiLocationContracts = false } = useFeatureFlag('enableMultiLocationContracts');
    const {
        associatedOrganizations,
        primaryPracticeForContract,
        practice,
        practiceLoading,
        doesNotHaveActiveContract,
        credits,
        creditsLoading,
        refetchCredits,
        refetchBillingDetails,
    } = useBillingDetailsContext();

    // make sure the primary is first in the carousel
    const allOrganizations = associatedOrganizations.sort((a, b) => {
        if (a.id === primaryPracticeForContract) {
            return -1;
        }
        if (b.id === primaryPracticeForContract) {
            return 1;
        }
        return 0;
    });

    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handleNextClick = () => {
        setCurrentIndex((currentIndex + 1) % associatedOrganizations.length);
    };

    const handlePrevClick = () => {
        setCurrentIndex((currentIndex - 1 + associatedOrganizations.length) % associatedOrganizations.length);
    };

    return (
        <Container>
            <LeftContainer>
                {enableMultiLocationContracts && (
                    <div>
                        <TitleText variant={'h5'}>{practice?.name} Overview</TitleText>
                        {practiceLoading && 'Loading...'}
                        {doesNotHaveActiveContract && 'No active contracts found for practice.'}
                        {primaryPracticeForContract === practiceId && !!associatedOrganizations?.length && (
                            <TitleText variant={'body1'}>Locations ({associatedOrganizations?.length})</TitleText>
                        )}
                    </div>
                )}
                <OverviewContainer idx={currentIndex}>
                    {allOrganizations.map(org => (
                        <OrganizationOverview key={org.id} organization={org} />
                    ))}
                </OverviewContainer>
                <PaginationContainer>
                    {associatedOrganizations?.length > 1 && (
                        <>
                            <IconButton size={'small'} onClick={handlePrevClick}>
                                <ArrowWestIcon />
                            </IconButton>
                            <IconButton size={'small'} onClick={handleNextClick}>
                                <ArrowEastIcon />
                            </IconButton>
                        </>
                    )}
                </PaginationContainer>

                {/* TODO: EPDB-953 invoices */}
                <InvoicesDetailPanel practiceId={practiceId} />
            </LeftContainer>
            <ContractsAndCreditsContainer>
                <PartnerContractsTable organizationId={practiceId} />
                {!creditsLoading && (
                    <CreditsContainer>
                        <PartnerCreditsTable
                            onRefresh={refetchCredits}
                            practiceId={practiceId}
                            practiceName={practice?.name ?? ''}
                            credits={credits ?? []}
                            refetchBillingDetails={refetchBillingDetails}
                        />
                    </CreditsContainer>
                )}
                {practice && (
                    <JsonDevTools
                        title={
                            <Text variant={'body1'} medium>
                                Dev Tools
                            </Text>
                        }
                        src={practice}
                        dataType={'PartnerBilling'}
                        expansionPanelStyle={ExpansionPanelStyle}
                        expansionPanelSummaryStyle={ExpansionPanelSummaryStyle}
                        expansionPanelDetailsStyle={{ ...ExpansionPanelStyle, marginTop: '24px' }}
                    />
                )}
            </ContractsAndCreditsContainer>
        </Container>
    );
};
