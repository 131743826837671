import { AdminLabsEditInvoicingLineItemsAction } from '../../../../../Billing/actions/AdminLabsEditInvoicingLineItemsAction.graphql';
import { SoftSmilePlanVersions } from './SoftsmilePlanVersions.graphql';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { Grid } from '@orthly/ui-primitives';
import { EditAlignerCaseAction, OrderDetailAligners } from '@orthly/veneer';
import React from 'react';

export const OrderDetailSKU: React.FC<{ order: LabsGqlOrder; refetchOrder: () => Promise<any> }> = ({
    order,
    refetchOrder,
}) => {
    const { aligner_case } = order;

    if (aligner_case) {
        return (
            <OrderDetailAligners
                lab_order={order}
                aligner_case={aligner_case}
                refetch={refetchOrder}
                user_role={`psr`}
                actions={
                    <Grid container>
                        <EditAlignerCaseAction order={order} refetchOrder={refetchOrder} />
                        <Grid item style={{ paddingLeft: 8 }}>
                            <AdminLabsEditInvoicingLineItemsAction order={order} refetchOrder={refetchOrder} />
                        </Grid>
                    </Grid>
                }
                SoftSmilePlanVersionsBlock={SoftSmilePlanVersions}
            />
        );
    }

    return null;
};
