import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { applyAppearanceFilter } from '@orthly/dentin';
import { ToothUtils } from '@orthly/items';
import { LowerJawIcon, UpperJawIcon } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Switch,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    archToggleContainer: {
        paddingLeft: 20,
        paddingTop: 15,
        flexDirection: 'row',
    },
    archToggleButton: {
        paddingTop: 10,
    },
    iconTextContainer: {
        padding: 5,
        alignItems: 'center',
    },
    jawIcon: {
        color: FlossPalette.PRIMARY_FOREGROUND,
        marginTop: 5,
    },
    activeToggle: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    },
    inactiveToggle: {
        backgroundColor: FlossPalette.WHITE,
    },
}));

const JawIcon: React.VFC<{ isUpper: boolean }> = ({ isUpper }) => {
    const classes = useStyles();
    return isUpper ? <LowerJawIcon className={classes.jawIcon} /> : <UpperJawIcon className={classes.jawIcon} />;
};

export const OcclusalAnatomyControls: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { setAppearance, teeth } = useGuidedWaxupContext();
    const isUpper = teeth.every(tooth => ToothUtils.toothIsUpper(tooth));
    const [showOpposing, setShowOpposing] = React.useState(false);
    const mobileButtonBackground = showOpposing ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.WHITE;

    const toggleOpposingArch = () => {
        setShowOpposing(prevShowOpposing => {
            const nextShowOpposing = !prevShowOpposing;
            setAppearance(currentAppearance =>
                applyAppearanceFilter(currentAppearance, {
                    showColor: false,
                    showRestos: true,
                    showScans: true,
                    showUpper: nextShowOpposing ? !isUpper : isUpper,
                    showLower: nextShowOpposing ? isUpper : !isUpper,
                }),
            );
            return nextShowOpposing;
        });
    };

    return (
        <Grid item container className={classes.archToggleContainer}>
            <Button
                variant={'secondary'}
                onClick={toggleOpposingArch}
                className={classes.archToggleButton}
                style={{ backgroundColor: isMobile ? mobileButtonBackground : undefined }}
            >
                <Grid container className={classes.iconTextContainer} direction={isMobile ? 'column' : 'row'}>
                    <JawIcon isUpper={isUpper} />
                    {!isMobile && <Switch color={'secondary'} checked={showOpposing} />}
                    <Text
                        variant={isMobile ? 'caption' : 'body2'}
                        color={'PRIMARY_FOREGROUND'}
                        medium
                        style={{ marginBottom: isMobile ? 10 : 0 }}
                    >
                        Opposing arch{!isMobile && ` ${showOpposing ? 'on' : 'off'}`}
                    </Text>
                </Grid>
            </Button>
        </Grid>
    );
};
