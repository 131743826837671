import { DesignViewerLiteButtonGroups } from '../../DesignViewerLiteButtonGroups';
import { DesignViewerLiteDetachedContainer } from '../../DesignViewerLiteDetachedContainer';
import { isGuidedPreset, useReviewHasStructuredNotes } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import type { CurrentWaxupModelViewerProps } from './CurrentWaxupModelViewerControls';
import { GeneralReviewStructuredRejectionNotes } from './GeneralReviewStructuredRejectionNotes';
import { DraggableQCToolbar } from '@orthly/dentin';
import { Grid, Text, stylesFactory, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    designViewerContainer: {
        padding: '0px 20px',
        marginTop: 20,
        [theme.breakpoints.down('lg')]: {
            padding: 0,
            marginTop: 0,
        },
    },
    buttonGroupsContainer: {
        paddingLeft: 20,
        paddingTop: 15,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingLeft: 16,
            paddingRight: 16,
            display: 'block',
        },
    },
    desktopRejectionNoteDisplay: {
        padding: '0px 20px',
    },
    rejectionNoteContainer: {
        flexDirection: 'column',
        alignContent: 'flex-start',
    },
}));

interface HistoricalWaxupModelViewerProps extends CurrentWaxupModelViewerProps {
    selectedDesignRevisionNotes?: string;
}

export const HistoricalWaxupModelViewerAndControls: React.VFC<HistoricalWaxupModelViewerProps> = ({
    selectedDesignRevisionNotes,
    style,
}) => {
    const reviewHasStructuredNotes = useReviewHasStructuredNotes();
    const classes = useStyles();
    const {
        appearance,
        setAppearance,
        externalAppearanceApp,
        modelRef,
        controlRef,
        zoom,
        order,
        refetch,
        selectedTab,
        setAnnotation,
        isMobileRejectionNoteOpen,
        setIsMobileRejectionNoteOpen,
    } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const guidedPreset = isGuidedPreset(selectedTab);
    return (
        <>
            <Grid item className={classes.designViewerContainer}>
                <DesignViewerLiteDetachedContainer
                    order={order}
                    refetchDesign={refetch}
                    appearance={appearance}
                    setAppearance={setAppearance}
                    externalAppearanceApp={externalAppearanceApp}
                    modelRef={modelRef}
                    camConfig={{
                        zoom,
                    }}
                    controlRef={controlRef}
                    setPresetAnnotation={setAnnotation}
                    style={style}
                />
                <DraggableQCToolbar
                    dynamicHeatmaps={true}
                    appearance={appearance}
                    setAppearance={setAppearance}
                    hideZoom
                />
            </Grid>
            {!guidedPreset && (
                <Grid
                    item
                    container
                    className={classes.buttonGroupsContainer}
                    justifyContent={isMobile ? 'flex-start' : 'space-between'}
                >
                    <Grid item>
                        <DesignViewerLiteButtonGroups
                            externalAppearanceApp={externalAppearanceApp}
                            appearance={appearance}
                            setAppearance={setAppearance}
                            dynamicHeatmaps={true}
                        />
                    </Grid>
                    {reviewHasStructuredNotes ? (
                        <GeneralReviewStructuredRejectionNotes
                            title={'View rejection note'}
                            closeLabel={'Close'}
                            isOpen={isMobileRejectionNoteOpen}
                            onBack={() => setIsMobileRejectionNoteOpen(false)}
                            onClose={() => setIsMobileRejectionNoteOpen(false)}
                        />
                    ) : (
                        !isMobile &&
                        !!selectedDesignRevisionNotes && (
                            <Grid item xs={3}>
                                <Grid item container className={classes.rejectionNoteContainer}>
                                    <Grid item>
                                        <Text variant={'body1'} medium>
                                            Rejection Note
                                        </Text>
                                    </Grid>
                                    <Grid item>
                                        <Text variant={'body1'}>{selectedDesignRevisionNotes}</Text>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
            )}
        </>
    );
};
