import { DesignTrainingCompletedOrders } from './Components/DesignTrainingCompletedOrders';
import { DesignTrainingHeaderContainer } from './Components/DesignTrainingHeaderContainer';
import { DesignTrainingMiniViewer } from './Components/DesignTrainingMiniViewer';
import { DesignTrainingStartNextCard } from './Components/DesignTrainingStartNextCard';
import { useQuery } from '@apollo/client';
import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { UuidUtils } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { GhostIconButton, StackX, StackY, useQueryParam } from '@orthly/ui';
import { Text, FlossPalette, styled, LinearProgress, ChevronLeft } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

const DesignTrainingOrder_Fragment = graphql(`
    fragment DesignTrainingOrder on DesignTrainingOrderDTO {
        designRevisionId
        id
        orderId
        orderNumber
        completedAt
        automateDesignApproved
        designOrderId
        latestDesignRevisionId
        designDurationMs
        type
    }
`);

const EnrolledCourse_Query = graphql(`
    query EnrolledCourse_Query($courseId: String!) {
        getDesignTrainingEnrolledCourse(courseId: $courseId) {
            id
            identifier
            name
            userId
            userName
            courseWork {
                ...DesignTrainingOrder
            }
        }
    }
`);

type CompleteTrainingItem = DesignTrainingOrderFragment & { completedAt: string };

function isTrainingOrderComplete(item: DesignTrainingOrderFragment): item is CompleteTrainingItem {
    return !!item.completedAt;
}

const MainCard = styled('div')({
    display: 'flex',
    flexFlow: 'column nowrap',
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: 8,
    padding: 24,
    margin: 24,
    background: FlossPalette.TAN,
    minWidth: 1200,
    maxWidth: 1600,
    height: 760,
});

const ViewerContainer = styled(StackY)({
    alignSelf: 'stretch',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
});

export const DesignTrainingCourseBody: React.VFC<{}> = () => {
    const { courseId } = useParams<{ courseId: string }>();
    const history = useHistory();
    const session = useSession();
    const { data, loading, refetch } = useQuery(EnrolledCourse_Query, {
        variables: { courseId },
        skip: !UuidUtils.isUUID(courseId),
    });

    const [previewTrainingOrderId, setPreviewTrainingOrderId] = useQueryParam('pv');

    if (loading) {
        return <LinearProgress />;
    }
    if (!data) {
        return <Redirect to={`/training`} />;
    }

    const {
        name: courseName,
        courseWork,
        userId: courseUserId,
        userName: courseUserName,
    } = data.getDesignTrainingEnrolledCourse;
    // `isOwnCourse` is true when viewing one's own training course;
    // false when viewing another's in evaluation mode.
    const isOwnCourse = session?.user_id === courseUserId;
    const trainingOrders = courseWork.map(itemFragment => getFragmentData(DesignTrainingOrder_Fragment, itemFragment));
    const nextOrderInCourse = _.sortBy(trainingOrders, o => o.orderNumber).find(o => !isTrainingOrderComplete(o));
    let completedOrders = trainingOrders.filter(isTrainingOrderComplete);
    if (isOwnCourse) {
        // Order by date, descending
        completedOrders = completedOrders.sort(
            (a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime(),
        );
    } else {
        // Order by number
        completedOrders = _.sortBy(completedOrders, o => o.orderNumber);
    }
    const previewTrainingOrder = completedOrders.find(o => o.id === previewTrainingOrderId);
    const approvedCount = _.sumBy(completedOrders, o => (o.automateDesignApproved ? 1 : 0));

    return (
        <>
            <DesignTrainingHeaderContainer>
                <GhostIconButton
                    size={'large'}
                    onClick={() => history.push(isOwnCourse ? '/training' : '/training?tab=eval')}
                >
                    <ChevronLeft />
                </GhostIconButton>
                <Text variant={'h5'} medium>
                    {isOwnCourse ? `Dandy design training: ${courseName}` : `${courseUserName}: ${courseName}`}
                </Text>
            </DesignTrainingHeaderContainer>
            <MainCard>
                <StackX style={{ gap: 16, flexGrow: 1 }}>
                    <StackY style={{ alignItems: 'stretch', flexBasis: '550px' }}>
                        {isOwnCourse && nextOrderInCourse && (
                            <DesignTrainingStartNextCard
                                trainingOrder={nextOrderInCourse}
                                onComplete={() => {
                                    void refetch();
                                    setPreviewTrainingOrderId(undefined);
                                }}
                            />
                        )}
                        {!isOwnCourse && (
                            <Text variant={'body2'} style={{ marginBottom: 16 }}>
                                {`Approved ${approvedCount}/${completedOrders.length} (${((100 * approvedCount) / completedOrders.length).toFixed(0)}%) of orders`}
                            </Text>
                        )}

                        {isOwnCourse && (
                            <Text variant={'body1'} medium style={{ marginTop: 16, marginBottom: 16 }}>
                                Your recently completed cases
                            </Text>
                        )}
                        {completedOrders.length > 0 ? (
                            <DesignTrainingCompletedOrders
                                completedOrders={completedOrders}
                                previewTrainingOrderId={previewTrainingOrderId}
                                setPreviewTrainingOrderId={setPreviewTrainingOrderId}
                                onComplete={() => {
                                    void refetch();
                                    setPreviewTrainingOrderId(undefined);
                                }}
                                isOwnCourse={isOwnCourse}
                            />
                        ) : (
                            <Text variant={'body2'} color={'GRAY'}>
                                You have not completed any training cases yet.
                            </Text>
                        )}
                    </StackY>

                    <ViewerContainer>
                        {previewTrainingOrder ? (
                            <DesignTrainingMiniViewer trainingOrder={previewTrainingOrder} />
                        ) : (
                            <Text variant={'body2'} color={'GRAY'}>{`Select a case for preview`}</Text>
                        )}
                    </ViewerContainer>
                </StackX>
            </MainCard>
        </>
    );
};
