import { ButtonWithProgress } from './ButtonWithProgress';
import { type DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { useOrder } from '@orthly/graphql-react';
import { Button, type ButtonProps } from '@orthly/ui-primitives';
import { DesignPrepRoot } from '@orthly/veneer';
import React from 'react';

export const DesignTrainingStartPiBButton: React.VFC<{
    trainingOrder: DesignTrainingOrderFragment;
    onComplete: () => void;
    /** Button children. Usually just the button text. */
    children?: React.ReactNode;
    /** Whether to prefetch order and design revision data or wait until the user clicks the button. Default is `false`. */
    prefetch?: boolean;
    /** The button component. Default is `Button`. */
    ButtonComponent?: React.ComponentType<ButtonProps>;
    /** The button variant. Default is `primary`. */
    buttonVariant?: ButtonProps['variant'];
}> = props => {
    const {
        trainingOrder,
        onComplete,
        children,
        prefetch = false,
        ButtonComponent = Button,
        buttonVariant = 'primary',
    } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    // Skip queries when not prefetching and the user hasn't clicked the button to start.
    const skip = !prefetch && !open;

    const { order: labOrderDataRaw, refetch } = useOrder(trainingOrder.orderId, { skip });
    const labOrderData = labOrderDataRaw && {
        ...labOrderDataRaw,
        patient: { ...labOrderDataRaw.patient, first_name: `Training #${trainingOrder.orderNumber}`, last_name: '' },
    };

    return (
        <>
            <ButtonWithProgress
                ButtonComponent={ButtonComponent}
                variant={buttonVariant}
                loading={open && !labOrderData}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {children}
            </ButtonWithProgress>
            {labOrderData && (
                <DesignPrepRoot
                    key={labOrderData.id}
                    order={labOrderData}
                    sidebarEntries={[]}
                    open={open}
                    setOpen={setOpen}
                    shouldDoDownload={true}
                    trainingDesignId={trainingOrder.id}
                    onComplete={onComplete}
                    refetch={refetch}
                />
            )}
        </>
    );
};
