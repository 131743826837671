import { type ICameraControls, ModelViewerControls } from '../ModelViewer';
import { type ScanReviewViewManager } from './ScanReviewViewTypes';
import { OrthographicCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

export const ScanReviewPanel: React.FC<{ viewManager: ScanReviewViewManager }> = ({ viewManager, ...props }) => {
    const initializeCameraControlsCallback = React.useCallback(
        (controls: ICameraControls) => {
            viewManager.initializeControls(controls);
        },
        [viewManager],
    );

    React.useEffect(() => {
        return () => {
            viewManager.updateViewStateFromControls();
        };
    }, [viewManager]);

    return (
        <Canvas
            ref={viewManager.canvasRef}
            orthographic={true}
            linear={true}
            mode={'concurrent'}
            gl={{ preserveDrawingBuffer: true }}
            onCreated={({ gl }) => {
                gl.toneMapping = THREE.NoToneMapping;
                gl.outputEncoding = THREE.LinearEncoding;
            }}
        >
            <fog color={new THREE.Color(0x001e45)} near={100} far={1000} />

            <OrthographicCamera ref={viewManager.cameraRef} makeDefault zoom={4} position={[0, 0, 100]}>
                <pointLight intensity={0.8} color={0xffffff} />
            </OrthographicCamera>
            <ModelViewerControls ref={initializeCameraControlsCallback} use3ShapeViewerControls={true} />
            <ambientLight intensity={0.05} color={0xffffff} />
            <primitive object={viewManager.scene.scene} />
            {props.children}
        </Canvas>
    );
};
