import type { ScanReviewDcmFileData } from '../ScanReviewDcm.utils';
import type {
    ToothPlacementVisualizationJawRecord,
    ToothPlacementVisualizationJawRecordFactory,
} from './ToothPlacementVisualizationTypes';
import { getCanonicalTeethCoords3D } from '@orthly/forceps';
import { type ToothNumber } from '@orthly/items';
import * as THREE from 'three';

// Placeholder spoofed function for now.
// Editing this will allow for hot reloads of tooth position
// upper and lower jaw records will be used by the to be
// developed tooth placement algorithm.
export function prototypeToothPlacementFunction(
    toothNumber: ToothNumber,
    scanMesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>,
    _upperJaw: ToothPlacementVisualizationJawRecord | null,
    _lowerJaw: ToothPlacementVisualizationJawRecord | null,
) {
    const pos = getCanonicalTeethCoords3D().get(toothNumber) ?? new THREE.Vector3(0, 0, 0);
    const dummyPosition = new THREE.Vector3(pos.x, pos.z, pos.y);
    const dummyQuaternion = new THREE.Quaternion();

    scanMesh.geometry.center();
    scanMesh.position.copy(dummyPosition);
    scanMesh.quaternion.copy(dummyQuaternion);
}

export function loadJawScanDcmForToothPlacementVisualization(
    jawData: ScanReviewDcmFileData,
    onLoadCallback: (
        geometry: THREE.BufferGeometry,
        facetMarks: Uint32Array | undefined,
        image: HTMLImageElement,
    ) => ToothPlacementVisualizationJawRecordFactory,
    factorySetter: (value: React.SetStateAction<ToothPlacementVisualizationJawRecordFactory | null>) => void,
) {
    if (!jawData.geometry || !jawData.textureData) {
        return;
    }
    const scanImage = new Image();
    const scanGeometry = jawData.geometry.clone();
    const facetMarks = jawData.dcmFile.dcmManager.parseFacetMarks();
    scanImage.onload = () => {
        factorySetter(() => onLoadCallback(scanGeometry, facetMarks, scanImage));
    };
    scanImage.src = `data:image/jpeg;base64,${jawData.textureData}`;
}
