import { EditPracticeInformationModal } from '../../EditPracticeInformationModal';
import { Button } from '@mui/material';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { Box, FlossPalette, Text } from '@orthly/ui-primitives';
import React from 'react';

interface ShareScansWithPatientTitleProps {
    organizationName: string | null;
    setOrganizationName: (organizationName: string) => void;
    organizationPhone: string | null;
    setOrganizationPhone: (organizationPhone: string) => void;
    setShareModalOpen: (open: boolean) => void;
    organizationId?: string;
}

export const ShareScansWithPatientTitle: React.FC<ShareScansWithPatientTitleProps> = ({
    organizationName,
    setOrganizationName,
    setOrganizationPhone,
    organizationPhone,
    setShareModalOpen,
    organizationId,
}) => {
    const [open, setOpen] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(true);

    React.useEffect(() => {
        if (!organizationName || !organizationPhone) {
            setIsEditing(false);
            setOpen(true);
        } else {
            setIsEditing(true);
            setOpen(false);
        }
    }, [organizationName, organizationPhone]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
            }}
        >
            <Box>
                <Text variant={'h4'}>Share scans with patient</Text>
            </Box>
            <Box
                sx={{
                    border: `1px solid ${FlossPalette.DARK_TAN}`,
                    borderRadius: '16px',
                    paddingLeft: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '12px',
                }}
            >
                <Text variant={'body2'} color={'GRAY'} style={{ marginRight: '8px' }}>
                    Contact info
                </Text>
                <Text variant={'body2'} medium color={'BLACK'}>
                    {organizationName}
                </Text>
                <Text variant={'body2'} color={'GRAY'}>
                    {organizationPhone && PhoneNumberUtils.prettyPhoneNumber(organizationPhone)}
                </Text>
                <Button variant={'text'} onClick={() => setOpen(true)}>
                    Edit
                </Button>
            </Box>
            <EditPracticeInformationModal
                isEditing={isEditing}
                open={open}
                setOpen={setOpen}
                setOrganizationName={setOrganizationName}
                setOrganizationPhone={setOrganizationPhone}
                setShareModalOpen={setShareModalOpen}
                organizationName={organizationName}
                organizationPhone={organizationPhone}
                organizationId={organizationId}
            />
        </Box>
    );
};
