import { TabLabel } from '../../components/RouterTabs';
import { ButtonWithProgress } from './Components/ButtonWithProgress';
import { DesignTrainingHeaderContainer } from './Components/DesignTrainingHeaderContainer';
import { DesignTrainingCourseBody } from './DesignTrainingCoursePage.graphql';
import { EvaluationBody } from './EvaluationBody.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { SessionGuardUtils, useSession } from '@orthly/session-client';
import { StackX, StackY, ToolbarContainer, useQueryParam, useRootActionCommand } from '@orthly/ui';
import { Button, FlossPalette, styled, Tab, Tabs, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

const MyCourses_Query = graphql(`
    query MyCourses_Query {
        listMyDesignTrainingCourses {
            availableCourses {
                identifier
                name
            }
            enrolledCourses {
                id
                identifier
                name
            }
        }
    }
`);

const EnrollInCourse_Mutation = graphql(`
    mutation EnrollInCourse_Mutation($identifier: String!) {
        enrollInDesignTrainingCourse(identifier: $identifier) {
            id
            name
        }
    }
`);

const COURSE_DESCRIPTIONS: Record<string, string> = {
    finishing_in_portal: 'Practice reviewing orders from 3Shape Automate and finishing designs in the portal.',
    prep_in_browser_p1_1: 'Train the design team on the new Prep in Browser workflow. Level P1. Part 1.',
    prep_in_browser_p1_2: 'Train the design team on the new Prep in Browser workflow. Level P1. Part 2.',
    prep_in_browser_p1_3: 'Train the design team on the new Prep in Browser workflow. Level P1. Part 3.',
    prep_in_browser_p2: 'Train the design team on the new Prep in Browser workflow. Level P2',
    prep_in_browser_p3: 'Train the design team on the new Prep in Browser workflow. Level P3',
    prep_in_browser_a1: 'Train the design team on the new Prep in Browser workflow. Level A1',
    prep_in_browser_a2: 'Train the design team on the new Prep in Browser workflow. Level A2',
    prep_in_browser_a4: 'Train the design team on the new Prep in Browser workflow. Level A4',
};

const StyledCourseCard = styled(StackX)({
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: 8,
    padding: 24,
    background: FlossPalette.TAN,
    margin: 24,
    alignSelf: 'flex-start',
    width: 520,
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
});

const CourseCard: React.VFC<{ course: { identifier: string; name: string }; children?: React.ReactNode }> = ({
    course,
    children,
}) => {
    const description = COURSE_DESCRIPTIONS[course.identifier];
    return (
        <StyledCourseCard>
            <StackY>
                <Text variant={'body1'} medium>
                    {course.name}
                </Text>
                {description && <Text variant={'body2'}>{description}</Text>}
            </StackY>
            {children}
        </StyledCourseCard>
    );
};

const MyCoursesBody: React.VFC<{}> = () => {
    const history = useHistory();
    const { data } = useQuery(MyCourses_Query);
    const myCourses = data?.listMyDesignTrainingCourses;

    const { submit: enroll, submitting: enrollLoading } = useRootActionCommand(useMutation(EnrollInCourse_Mutation), {
        onSuccess: ({ data }) => {
            history.push(`/training/courses/${data.enrollInDesignTrainingCourse.id}`);
        },
    });

    if (!myCourses) {
        return null;
    }

    return (
        <>
            {myCourses.enrolledCourses.length > 0 && (
                <>
                    <Text variant={'h6'} style={{ marginLeft: 24, marginTop: 24 }}>
                        Enrolled courses
                    </Text>
                    {myCourses.enrolledCourses.map(course => (
                        <CourseCard key={course.identifier} course={course}>
                            <Button
                                style={{ flexShrink: 0 }}
                                variant={'primary'}
                                onClick={() => {
                                    history.push(`/training/courses/${course.id}`);
                                }}
                            >
                                Resume
                            </Button>
                        </CourseCard>
                    ))}
                </>
            )}

            {myCourses.availableCourses.length > 0 && (
                <>
                    <Text variant={'h6'} style={{ marginLeft: 24, marginTop: 24 }}>
                        Available courses
                    </Text>

                    {myCourses.availableCourses.map(course => (
                        <CourseCard key={course.identifier} course={course}>
                            <ButtonWithProgress
                                style={{ flexShrink: 0 }}
                                variant={'primary'}
                                loading={enrollLoading}
                                onClick={() => {
                                    void enroll({ identifier: course.identifier });
                                }}
                            >
                                Start course
                            </ButtonWithProgress>
                        </CourseCard>
                    ))}
                </>
            )}
        </>
    );
};

const canEvaluate = SessionGuardUtils.hasCapability('design_training', 'design_training.evaluate');

const StyledTabs = styled(Tabs)({ paddingLeft: 24 });

const MY_COURSES_TAB_ID = 'courses';
const EVALUATION_TAB_ID = 'eval';

export const DesignTrainingRoot: React.VFC<{}> = () => {
    const enableDesignTrainingFrontend = useFeatureFlag('enableDesignTrainingFrontend').value;
    const [tab, setTab] = useQueryParam('tab');
    const session = useSession();

    if (enableDesignTrainingFrontend === undefined) {
        return null;
    } else if (enableDesignTrainingFrontend === false) {
        return <Redirect to={'/'} />;
    }

    const enableEvaluation = canEvaluate(session);

    return (
        <StackY style={{ width: '100%' }}>
            <Switch>
                <Route exact path={'/training'}>
                    <DesignTrainingHeaderContainer>
                        <Text variant={'h5'} medium>
                            Dandy design training
                        </Text>
                    </DesignTrainingHeaderContainer>
                    {/* Only render tabs when the evaluation UI is enabled for the user. */}
                    {enableEvaluation && (
                        <ToolbarContainer>
                            <StyledTabs
                                indicatorColor={'secondary'}
                                textColor={'inherit'}
                                value={tab ?? MY_COURSES_TAB_ID}
                                onChange={(_evt, value) => setTab(value)}
                            >
                                <Tab label={<TabLabel>My Courses</TabLabel>} value={MY_COURSES_TAB_ID} />
                                <Tab label={<TabLabel>Evaluation</TabLabel>} value={EVALUATION_TAB_ID} />
                            </StyledTabs>
                        </ToolbarContainer>
                    )}
                    {tab === EVALUATION_TAB_ID ? <EvaluationBody /> : <MyCoursesBody />}
                </Route>
                <Route path={'/training/courses/:courseId'}>
                    <DesignTrainingCourseBody />
                </Route>
            </Switch>
        </StackY>
    );
};
