import { DesignViewerLiteButtonGroups } from '../../DesignViewerLiteButtonGroups';
import { DesignViewerLiteDetachedContainer } from '../../DesignViewerLiteDetachedContainer';
import { isGuidedPreset } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { CurrentWaxupGeneralStructuredNotesControls } from './CurrentWaxupGeneralStructuredNotesControls';
import { GeneralReviewRejectionControlsDesktop } from './GeneralReviewRejectionControlsDesktop';
import { MarginViewControls } from './MarginViewControls';
import { OcclusalAnatomyControls } from './OcclusalAnatomyControls';
import { DraggableQCToolbar } from '@orthly/dentin';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Grid, stylesFactory, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    designViewerContainer: {
        padding: '0px 20px',
        marginTop: 20,
        [theme.breakpoints.down('lg')]: {
            padding: 0,
            marginTop: 0,
        },
    },
    buttonGroupsContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            paddingLeft: 16,
            paddingRight: 16,
            display: 'block',
        },
    },
    generalViewNoteContainer: {
        paddingRight: 20,
    },
}));

export interface CurrentWaxupModelViewerProps {
    style?: React.CSSProperties;
}

export const CurrentWaxupModelViewerAndControls: React.VFC<CurrentWaxupModelViewerProps> = ({ style }) => {
    const classes = useStyles();
    const {
        enableStructuredDesignReviewNotes,
        appearance,
        setAppearance,
        externalAppearanceApp,
        modelRef,
        controlRef,
        zoom,
        order,
        refetch,
        selectedTab,
        setAnnotation,
    } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const guidedPreset = isGuidedPreset(selectedTab);

    return (
        <>
            <Grid item className={classes.designViewerContainer}>
                <DesignViewerLiteDetachedContainer
                    order={order}
                    refetchDesign={refetch}
                    appearance={appearance}
                    setAppearance={setAppearance}
                    externalAppearanceApp={externalAppearanceApp}
                    modelRef={modelRef}
                    controlRef={controlRef}
                    setPresetAnnotation={setAnnotation}
                    camConfig={{
                        zoom,
                    }}
                    style={style}
                />
                {
                    <DraggableQCToolbar
                        dynamicHeatmaps={true}
                        appearance={appearance}
                        setAppearance={setAppearance}
                        hideZoom
                    />
                }
            </Grid>
            {!guidedPreset && (
                <Grid
                    item
                    container
                    className={classes.buttonGroupsContainer}
                    justifyContent={enableStructuredDesignReviewNotes || isMobile ? 'flex-start' : 'space-between'}
                    flexDirection={enableStructuredDesignReviewNotes ? 'column' : 'row'}
                >
                    <Grid item>
                        <DesignViewerLiteButtonGroups
                            externalAppearanceApp={externalAppearanceApp}
                            appearance={appearance}
                            setAppearance={setAppearance}
                            dynamicHeatmaps={true}
                        />
                    </Grid>
                    {!isMobile && !enableStructuredDesignReviewNotes && (
                        <Grid item xs={3} className={classes.generalViewNoteContainer}>
                            <GeneralReviewRejectionControlsDesktop />
                        </Grid>
                    )}
                    {enableStructuredDesignReviewNotes && <CurrentWaxupGeneralStructuredNotesControls />}
                </Grid>
            )}
            {selectedTab === LabsGqlGuidedWaxupPresetType.MarginView && <MarginViewControls />}
            {selectedTab === LabsGqlGuidedWaxupPresetType.OcclusalAnatomy && <OcclusalAnatomyControls />}
        </>
    );
};
