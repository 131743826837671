import { graphql } from '@orthly/graphql-inline-react';

export const ProductionPlanDebuggerStep_Fragment = graphql(`
    fragment ProductionPlanDebuggerStep on ProductionPlanDebuggerStep {
        id
        name
        type
        backgroundColor
        dependencies
        plannedEnd
        plannedStart
        project
        tooltipMeta {
            isDate
            label
            value
        }
    }
`);

export const ProductionPlanDebuggerView_Fragment = graphql(`
    fragment ProductionPlanDebuggerView on ProductionPlanDebuggerView {
        id
        committedDateInput
        orderCreated
        earliestArrivalDate
        requirementSchedule {
            ...ProductionPlanDebuggerStep
        }
        plans {
            ...ProductionPlanDebuggerStep
        }
        potentialAssignments {
            ...ProductionPlanPotentialAssignment
        }
        resultsAreFiltered
        totalPlansConsidered
        profilingResults
    }
`);

export const ProductionPlanPotentialAssignment_Fragment = graphql(`
    fragment ProductionPlanPotentialAssignment on ProductionPlanPotentialAssignment {
        id
        requirementId
        organizationId
        capacityWindowStart
        capacityWindowEnd
        capacityUtilizations {
            ruleId
            value
        }
        tooltipMeta {
            label
            value
        }
    }
`);
