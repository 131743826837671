import { graphql } from '@orthly/graphql-inline-react';

// TODO: EPDB-975: remove legacy queries
/** Single-location contract queries */
export const GetPartnerBillingOverviewData_Query = graphql(`
    query GetPartnerBillingOverviewData($partnerId: String!) {
        getPartnerBillingAccount(partner_id: $partnerId) {
            account_management_contact_emails
            activation_date
            activation_source
            agreed_to_cc_fees
            autocharge_enabled
            contact_emails
            id
            is_on_payment_plan
            primary_billing_contact_user_id
            primary_billing_contact_user_type
            stripe_customer_id
            usage_payment_source_config {
                ... on PaymentSplitConfigDoctor {
                    splits {
                        doctor_preferences_id
                        source_id
                    }
                    type
                }
                ... on PaymentSplitConfigLocation {
                    splits {
                        delivery_address_id
                        source_id
                    }
                    type
                }
                ... on PaymentSplitConfigPercent {
                    splits {
                        decimal_percentage
                        source_id
                    }
                    type
                }
            }
            will_be_charged_cc_fee
        }
        getOrganization(id: $partnerId) {
            name
        }
    }
`);

export const GetNextInvoiceStatus_Query = graphql(`
    query GetNextInvoiceStatus($organizationId: String!) {
        getNextInvoiceStatus(organizationId: $organizationId) {
            pending_item_count
            previous_invoice_amount_cents
            will_be_invoiced
        }
    }
`);

export const ListInvoiceCreditsForOrganization_Query = graphql(`
    query ListInvoiceCreditsForOrganization($organizationId: String!, $includeDeleted: Boolean!) {
        credits: listInvoiceCreditsForOrganization(organizationId: $organizationId, includeDeleted: $includeDeleted) {
            balance_cents
            amount_issued_cents
            created_at
            created_by_user_id
            credit_category_id
            description
            expires
            id
            organization_id
            deleted_at
            attribution {
                ... on InvoiceAdjustmentAttributionBase {
                    type
                }
                ... on OrderAdjustmentAttribution {
                    order_id
                }
                ... on OrderItemAdjustmentAttribution {
                    order_id
                    order_item_id
                }
                ... on InvoiceItemAdjustmentAttribution {
                    invoice_id
                    invoice_item_id
                }
            }
        }
    }
`);

export const GetInvoicesForOrganization_Query = graphql(`
    query ListInvoicesForOrganization($organizationId: String) {
        invoices: listInvoicesForOrganization(organizationId: $organizationId) {
            amount_due
            amount_paid
            amount_remaining
            created_at
            due_date
            id
            invoice_number
            organization_id
            period_end
            period_start
            payments {
                amount_cents
                created_at
                status
                error_summary
                refunds {
                    amount_cents
                    created_at
                }
                stripe_charge_id
                stripe_payment_intent_id
                stripe_payment_source_id
            }
            status
            summary_email_sent
            total
        }
    }
`);

export const NeedsBillingSummaryEmail_Query = graphql(`
    query NeedsBillingSummaryEmail($organizationId: String!) {
        needsBillingSummaryEmail(organizationId: $organizationId)
    }
`);

/** Multi-location contract queries */
export const GetPartnerBillingOverviewDataForContract_Query = graphql(`
    query GetPartnerBillingOverviewsForContract($partnerId: String!) {
        contractAccounts: getPartnerBillingAccountsForRelevantContract(partnerId: $partnerId) {
            account_management_contact_emails
            activation_date
            activation_source
            agreed_to_cc_fees
            autocharge_enabled
            contact_emails
            id
            is_on_payment_plan
            primary_billing_contact_user_id
            primary_billing_contact_user_type
            stripe_customer_id
            usage_payment_source_config {
                ... on PaymentSplitConfigDoctor {
                    splits {
                        doctor_preferences_id
                        source_id
                    }
                    type
                }
                ... on PaymentSplitConfigLocation {
                    splits {
                        delivery_address_id
                        source_id
                    }
                    type
                }
                ... on PaymentSplitConfigPercent {
                    splits {
                        decimal_percentage
                        source_id
                    }
                    type
                }
            }
            will_be_charged_cc_fee
        }
    }
`);

export const GetNextInvoiceStatusForContractOrgs_Query = graphql(`
    query GetNextInvoiceStatusForContractOrgs($organizationId: String!) {
        nextInvoiceStatusList: getNextInvoiceStatusForContractOrgs(organizationId: $organizationId) {
            organization_id
            next_invoice_status_summary {
                pending_item_count
                previous_invoice_amount_cents
                will_be_invoiced
            }
        }
    }
`);

export const ContractOrgNeedsBillingSummaryEmail_Query = graphql(`
    query NeedsBillingSummaryEmailForContract($organizationId: String!) {
        needsBillingSummaryEmailList: needsBillingSummaryForContract(organizationId: $organizationId) {
            needsSummaryEmail
            organizationId
        }
    }
`);
