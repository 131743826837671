import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { applyAppearanceFilter, useTeethIndices } from '@orthly/dentin';
import { ToothUtils } from '@orthly/items';
import { ModelTextureIcon } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Switch,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    colorToggleContainer: {
        paddingLeft: 20,
        paddingTop: 15,
        flexDirection: 'row',
    },
    iconTextContainer: {
        padding: 5,
        alignItems: 'center',
    },
    activeToggle: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10,
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            '&:hover': {
                backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            },
        },
    },
    inactiveToggle: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10,
            backgroundColor: FlossPalette.WHITE,
            '&:hover': {
                backgroundColor: FlossPalette.WHITE,
            },
        },
    },
}));

export const MarginViewControls: React.VFC = () => {
    const { appearance, setAppearance } = useGuidedWaxupContext();
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const teethIndices = useTeethIndices(appearance);
    const [showColor, setShowColor] = React.useState(false);
    const toggleModelColor = React.useCallback(() => {
        const activeToothIndex = teethIndices[0];
        const showUpperJaw = typeof activeToothIndex !== 'undefined' && ToothUtils.toothIsUpper(activeToothIndex);

        setShowColor(prevShowColor => {
            const nextShowColor = !prevShowColor;
            setAppearance(currentAppearance =>
                applyAppearanceFilter(currentAppearance, {
                    showRestos: false,
                    showScans: true,
                    showLower: !showUpperJaw,
                    showUpper: showUpperJaw,
                    showColor: nextShowColor,
                }),
            );
            return nextShowColor;
        });
    }, [setAppearance, teethIndices]);

    return (
        <Grid item container className={classes.colorToggleContainer}>
            <Button
                variant={'secondary'}
                onClick={toggleModelColor}
                className={showColor ? classes.activeToggle : classes.inactiveToggle}
            >
                <Grid container className={classes.iconTextContainer} direction={isMobile ? 'column' : 'row'}>
                    <ModelTextureIcon />
                    {!isMobile && <Switch color={'secondary'} checked={showColor} />}
                    Color{!isMobile && ` ${showColor ? 'on' : 'off'}`}
                </Grid>
            </Button>
        </Grid>
    );
};
