import { isFinishingCourse, isPrepCourse } from '../DesignTraining.utils';
import { DesignTrainingStartFinishingButton } from './DesignTrainingStartFinishingButton.graphql';
import { DesignTrainingStartPiBButton } from './DesignTrainingStartPiBButton.graphql';
import { NarrowButton } from './NarrowButton';
import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { DateUtils } from '@orthly/shared-types';
import { FlossPalette, styled, Table, TableFooter, TablePagination, TableRow, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React from 'react';

dayjs.extend(duration);

const TABLE_PAGE_SIZE = 12;
const TABLE_PAGE_SIZE_EVAL = 15;

const StyledGrid = styled('div')<{ isOwnCourse: boolean }>(({ isOwnCourse }) => ({
    display: 'grid',
    gridTemplateColumns: isOwnCourse ? `auto auto auto 1fr auto` : `auto auto auto auto 1fr`,
    columnGap: 16,
    paddingBottom: 8,
    borderBottom: `solid 1px ${FlossPalette.DARK_TAN}`,
}));

const GridRow = styled('div')({
    display: 'grid',
    gridColumn: '1 / span 5',
    gridTemplateColumns: 'subgrid',
    height: 40,
    borderRadius: 8,
    alignItems: 'center',
    padding: `0px 8px`,
});

const DurationText = styled(Text)({ justifySelf: 'end' });

type CompleteTrainingItem = DesignTrainingOrderFragment & { completedAt: string };

function formatDuration(durationMs: number): string {
    const duration = dayjs.duration(durationMs);
    return duration.hours() ? duration.format('h:mm:ss') : duration.format('m:ss');
}

/** Renders a paginated table for completed training orders. */
export const DesignTrainingCompletedOrders: React.VFC<{
    completedOrders: CompleteTrainingItem[];
    previewTrainingOrderId?: string;
    setPreviewTrainingOrderId: (id: string) => void;
    onComplete: () => void;
    /** Whether the orders belong to the current user (otherwise we are in evaluation mode). */
    isOwnCourse: boolean;
}> = ({ completedOrders, previewTrainingOrderId, setPreviewTrainingOrderId, onComplete, isOwnCourse }) => {
    const [tablePageNumber, setTablePageNumber] = React.useState(0);
    const pageSize = isOwnCourse ? TABLE_PAGE_SIZE : TABLE_PAGE_SIZE_EVAL;
    const currentPage = completedOrders.slice(tablePageNumber * pageSize, tablePageNumber * pageSize + pageSize);

    return (
        <>
            <StyledGrid isOwnCourse={isOwnCourse}>
                {currentPage.map(trainingOrder => {
                    const isPreviewing = previewTrainingOrderId === trainingOrder.id;
                    return (
                        <GridRow
                            key={trainingOrder.id}
                            style={{
                                backgroundColor: isPreviewing ? FlossPalette.PRIMARY_BACKGROUND : undefined,
                            }}
                        >
                            <Text variant={'body2'} medium={isPreviewing}>{`Case #${trainingOrder.orderNumber}`}</Text>
                            {isFinishingCourse(trainingOrder) ? (
                                <Text variant={'body2'} color={'GRAY'}>
                                    {trainingOrder.automateDesignApproved ? 'DVD Approved' : 'DVD Rejected'}
                                </Text>
                            ) : (
                                <Text />
                            )}
                            <Text variant={'body2'} color={'GRAY'}>
                                {DateUtils.relativeFormat(new Date(trainingOrder.completedAt))}
                            </Text>
                            {!isOwnCourse && (
                                <DurationText variant={'body2'} color={'GRAY'}>
                                    {typeof trainingOrder.designDurationMs === 'number'
                                        ? formatDuration(trainingOrder.designDurationMs)
                                        : '-'}
                                </DurationText>
                            )}
                            <NarrowButton
                                variant={'ghost'}
                                onClick={() => setPreviewTrainingOrderId(trainingOrder.id)}
                                disabled={isPreviewing}
                                startIcon={isPreviewing ? `VisibilityIcon` : undefined}
                            >
                                {isPreviewing ? `Previewing` : `Preview`}
                            </NarrowButton>
                            {isOwnCourse && isFinishingCourse(trainingOrder) && (
                                <DesignTrainingStartFinishingButton
                                    trainingOrder={trainingOrder}
                                    onComplete={onComplete}
                                    ButtonComponent={NarrowButton}
                                    buttonVariant={'ghost'}
                                >
                                    Redo
                                </DesignTrainingStartFinishingButton>
                            )}
                            {isOwnCourse && isPrepCourse(trainingOrder) && (
                                <DesignTrainingStartPiBButton
                                    trainingOrder={trainingOrder}
                                    onComplete={onComplete}
                                    ButtonComponent={NarrowButton}
                                    buttonVariant={'ghost'}
                                >
                                    Redo
                                </DesignTrainingStartPiBButton>
                            )}
                        </GridRow>
                    );
                })}
            </StyledGrid>
            <Table>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            style={{ borderBottom: 'none' }}
                            count={completedOrders.length}
                            rowsPerPageOptions={[pageSize]}
                            rowsPerPage={pageSize}
                            page={tablePageNumber}
                            onPageChange={(_event, pageNumber) => {
                                setTablePageNumber(pageNumber);
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    );
};
