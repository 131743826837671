import { PausedPracticeBanner } from '../../../components/PausedPractices/PausedPracticeBanner.graphql';
import { InvoicesDetailPanel } from '../InvoicesTable/InvoicesDetailPanel.graphql';
import { BillingDetailPanel } from './BillingDetailPanel.graphql';
import { LegacyBillingDetailPanel } from './LegacyBillingDetailPanel.graphql';
import { BillingDetailsCtxProvider } from './providers/BillingDetailsProvider.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

const TABS_HEIGHT = 64;
const BANNER_HEIGHT = 40;

const Container = styled('div')({
    padding: '16px',
    width: '100%',
    height: `calc(100vh - ${TABS_HEIGHT + BANNER_HEIGHT}px)`,
});

const GetActiveContractData_Query = graphql(`
    query GetActiveContractData($organizationId: String!) {
        contractAndAssociates: getContractAndAssociatedOrgsByOrganizationId(organization_id: $organizationId) {
            associatedPracticeIds
            associatedPractices {
                id
                name
            }
            contract {
                created_at
                deleted_at
                effective_end_date
                effective_start_date
                id
                organization_id
                pandadoc_contract_id
                spend_terms {
                    created_at
                    deleted_at
                    effective_end_date
                    effective_start_date
                    id
                    practice_contract_id
                    spend_cycle_count
                    spend_cycle_length
                    spend_minimum_cents
                    updated_at
                }
                updated_at
            }
            primaryPracticeId
        }
    }
`);

export const BillingDetailsRoot: React.FC<RouteComponentProps<{ practiceId: string }>> = props => {
    const { value: enableMultiLocationContracts } = useFeatureFlag('enableMultiLocationContracts');
    const { value: enableOpsPortalUXImprovements } = useFeatureFlag('enableOpsPortalUXImprovements');
    const practiceId = props.match.params.practiceId;

    const { data: { contractAndAssociates } = {} } = useQuery(GetActiveContractData_Query, {
        variables: { organizationId: practiceId },
        skip: !enableMultiLocationContracts,
    });

    const BillingDetailPanelComponent = enableOpsPortalUXImprovements ? BillingDetailPanel : LegacyBillingDetailPanel;

    return (
        <>
            <PausedPracticeBanner practiceId={practiceId} />
            <BillingDetailsCtxProvider practiceId={practiceId} contractAndAssociates={contractAndAssociates}>
                <Container>
                    <BillingDetailPanelComponent practiceId={practiceId} />
                    {!enableOpsPortalUXImprovements && <InvoicesDetailPanel practiceId={practiceId} />}
                </Container>
            </BillingDetailsCtxProvider>
        </>
    );
};
