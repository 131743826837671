import { isGuidedPreset, useGetSelectedWaxup, useSubmitGuidedWaxupReview } from './GuidedWaxup.util';
import type { CurrentWaxupModelViewerProps } from './components';
import { GuidedWaxupNavArea } from './components/GuidedWaxupNavArea';
import { useGuidedWaxupPresets } from './hooks/useGuidedWaxupPresets';
import { CurrentWaxupDesktopScreen } from './screens/CurrentWaxupDesktopScreen';
import { CurrentWaxupMobileScreen } from './screens/CurrentWaxupMobileScreen';
import { HistoricalWaxupDesktopScreen } from './screens/HistoricalWaxupDesktopScreen';
import { HistoricalWaxupMobileScreen } from './screens/HistoricalWaxupMobileScreen';
import { useGuidedWaxupContext } from './state/GuidedWaxupContext';
import { useGuidedWaxupSelector, useWaxupIsRejected } from './state/GuidedWaxupState';
import { PracticeScreen } from '@orthly/dentin';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
    LabsGqlGuidedWaxupPresetType,
} from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const HistoricalWaxupsScreens: React.VFC = () => {
    const { selectedTab } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const guidedPreset = isGuidedPreset(selectedTab);

    const selectedWaxup = useGetSelectedWaxup();
    const isApprovedWaxup = selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;

    // Always show the nav area if the selected revision was a rejection, but if vieweing an approved waxup,
    // we hide the nav area when viewing the general view preset since there's no rejection note to view
    const showNavArea = !isApprovedWaxup || guidedPreset;

    return isMobile ? (
        <>
            <HistoricalWaxupMobileScreen
                selectedDesignRevisionNotes={selectedWaxup?.notes ?? undefined}
                isApprovedWaxup={isApprovedWaxup}
            />
            {showNavArea && <GuidedWaxupNavArea />}
        </>
    ) : (
        <HistoricalWaxupDesktopScreen
            selectedDesignRevisionNotes={selectedWaxup?.notes ?? undefined}
            isApprovedWaxup={isApprovedWaxup}
        />
    );
};

const CurrentWaxupScreens: React.VFC<CurrentWaxupModelViewerProps> = () => {
    const history = useHistory();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { refetch, order } = useGuidedWaxupContext();
    const waxupRejected = useWaxupIsRejected();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const generalRejectionNote =
        useGuidedWaxupSelector(s => s.presets[LabsGqlGuidedWaxupPresetType.GeneralView]?.notes) ?? '';

    const { submit } = useSubmitGuidedWaxupReview(async () => {
        await refetch();
        history.replace(`/${PracticeScreen.orders}/${order.id}`);
    });

    const submitData = async () => {
        await submit({
            order_id: order.id,
            rejection: waxupRejected
                ? {
                      notes: generalRejectionNote,
                      presets: Object.entries(presets).map(([type, info]) => ({
                          preset_type: type as LabsGqlGuidedWaxupPresetType,
                          preset_status:
                              type === LabsGqlGuidedWaxupPresetType.GeneralView
                                  ? info.status ?? LabsGqlGuidedWaxupPresetStatus.Rejected
                                  : info.status ?? LabsGqlGuidedWaxupPresetStatus.Skipped,
                          annotated_image_urls: info.annotatedImageUrls ?? [],
                          structured_notes: info.structured_notes?.map(category => ({ category })) ?? [],
                          notes: info.notes ?? '',
                      })),
                  }
                : null,
        });
    };

    return isMobile ? (
        <>
            <CurrentWaxupMobileScreen />
            <GuidedWaxupNavArea submit={submitData} />
        </>
    ) : (
        <CurrentWaxupDesktopScreen submit={submitData} />
    );
};

export const GuidedWaxupScreenWrapper: React.VFC = () => {
    const { design, order, selectedDesignRevisionAlreadyReviewed, selectedDesignFragment } = useGuidedWaxupContext();
    useGuidedWaxupPresets(order, design);

    if (!selectedDesignFragment) {
        return null;
    }

    if (selectedDesignRevisionAlreadyReviewed) {
        return <HistoricalWaxupsScreens />;
    }

    return <CurrentWaxupScreens />;
};
