import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import type { PresetsRecord } from '../state/GuidedWaxupState';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { GeneralReviewStructuredRejectionNotes } from './GeneralReviewStructuredRejectionNotes';
import React from 'react';

const usePrevState = ({ presets }: { presets: PresetsRecord | undefined }) => {
    const prevState = React.useRef(presets);
    const setPrevState = React.useCallback((next: PresetsRecord | undefined) => {
        prevState.current = next;
    }, []);

    return [prevState.current, setPrevState] as const;
};

export const CurrentWaxupGeneralStructuredNotesControls: React.FC = () => {
    const { isMobileRejectionNoteOpen, setIsMobileRejectionNoteOpen, selectedDesignRevisionId, unfinishedPresets } =
        useGuidedWaxupContext();
    const overrideState = useGuidedWaxupAction('OVERRIDE_STATE');
    const presets = useGuidedWaxupSelector(s => s.presets);
    const [prevState, setPrevState] = usePrevState({ presets });
    const resetPresets = React.useCallback(() => {
        if (prevState) {
            overrideState({ designRevisionId: selectedDesignRevisionId, presets: prevState });
        }
        setIsMobileRejectionNoteOpen(false);
    }, [overrideState, prevState, selectedDesignRevisionId, setIsMobileRejectionNoteOpen]);

    return (
        <GeneralReviewStructuredRejectionNotes
            title={'Add a rejection note'}
            closeLabel={'Save'}
            closeDisabled={unfinishedPresets.length > 0}
            isOpen={isMobileRejectionNoteOpen}
            onCancel={resetPresets}
            onBack={resetPresets}
            onClose={() => {
                setIsMobileRejectionNoteOpen(false);
                setPrevState(presets);
            }}
        />
    );
};
