import { PoiRegroupDropdown } from '../DesignPrep/LeftSidebar/StageContents/PoiRegroupDropdown';
import { useAcceptDcmFiles } from './AcceptFilesFromBrowser.hooks';
import { File3dDropLoader } from './File3dDropLoader';
import { FormControlLabel } from '@mui/material';
import type { ToothPlacementVisualizationJawRecordFactory, ScanReviewViewJawDisplayState } from '@orthly/dentin';
import {
    type ScanReviewDcmFile,
    extractScanReviewDcmFileData,
    type ScanReviewViewState,
    ScanReviewPanelType,
    type ScanReviewRecordFactory,
    ScanReviewDcmBuilder,
    ToothPlacementVisualizationViewAppProvider,
    ToothPlacementVisualizationView,
    ScanReviewRecord,
    ToothPlacementVisualizationJawRecord,
    HIGHLIGHT_TOOTH_COLOR,
    loadJawScanDcmForToothPlacementVisualization,
} from '@orthly/dentin';
import { DcmManager } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import { StackY, StackX } from '@orthly/ui';
import { FlossPalette, Checkbox } from '@orthly/ui-primitives';
import React from 'react';
import type { DropEvent } from 'react-dropzone';
import * as THREE from 'three';

export const ToothPlacementVisualizationLandingPage: React.VFC = () => {
    const [dcms, setDcms] = React.useState<ScanReviewDcmFile[]>([]);
    const { onDcmDrop } = useAcceptDcmFiles(setDcms);

    const [upperJawTVFactory, setUpperJawTVFactory] =
        React.useState<ToothPlacementVisualizationJawRecordFactory | null>(null);
    const [lowerJawTVFactory, setLowerJawTVFactory] =
        React.useState<ToothPlacementVisualizationJawRecordFactory | null>(null);
    const [crownStateFactory, setCrownStateFactory] = React.useState<ScanReviewRecordFactory | null>(null);

    const [initialViewState, _setInitialViewState] = React.useState<ScanReviewViewState>({
        [ScanReviewPanelType.Upper]: null,
        [ScanReviewPanelType.Lower]: null,
        [ScanReviewPanelType.Left]: null,
        [ScanReviewPanelType.Right]: null,
        [ScanReviewPanelType.Front]: null,
    });

    // Jaw scan loading
    const onScanImageLoadCallback = (
        scanGeometry: THREE.BufferGeometry,
        facetMarks: Uint32Array | undefined,
        scanImage: HTMLImageElement,
    ) => {
        const factory = () => {
            const scanReviewRecord = new ScanReviewDcmBuilder(scanGeometry, scanImage)
                .buildDcmVertexColors()
                .complete();

            if (!scanReviewRecord) {
                return null;
            }
            const { scanMesh, scanMeshTexture, scanMeshMaterial } = scanReviewRecord;

            const toothVisualizationRecord = new ToothPlacementVisualizationJawRecord(
                scanMesh,
                scanMeshTexture,
                scanMeshMaterial,
                facetMarks,
            );
            return toothVisualizationRecord;
        };
        return factory;
    };
    React.useEffect(() => {
        const upperJawData = extractScanReviewDcmFileData(Jaw.UPPER, dcms);
        const lowerJawData = extractScanReviewDcmFileData(Jaw.LOWER, dcms);

        if (upperJawData) {
            loadJawScanDcmForToothPlacementVisualization(upperJawData, onScanImageLoadCallback, setUpperJawTVFactory);
        }
        if (lowerJawData) {
            loadJawScanDcmForToothPlacementVisualization(lowerJawData, onScanImageLoadCallback, setLowerJawTVFactory);
        }
    }, [dcms]);

    // Crown DCM loading
    const [crownDcmFile, setCrownDcmFile] = React.useState<DcmManager | null>(null);
    React.useEffect(() => {
        if (crownDcmFile) {
            const geometry = crownDcmFile.buildGeometry({ applyTextureCoords: true });
            geometry.computeVertexNormals();
            const material = new THREE.MeshStandardMaterial({ color: HIGHLIGHT_TOOTH_COLOR });
            const crownMesh = new THREE.Mesh(geometry, material);
            const scanReviewRecord = new ScanReviewRecord(crownMesh, new THREE.Texture(), material);
            setCrownStateFactory(() => () => scanReviewRecord);
        }
    }, [crownDcmFile]);
    const onCrownDcmDrop = React.useCallback<(file: File | undefined, event: DropEvent) => void>(
        async (file: File | undefined) => {
            if (file) {
                const injector = DcmManager.tryBuildDcmManager(await file.text());
                if (!injector) {
                    console.warn(`Failed to parse DCM file: ${file.name}`);
                    return;
                }
                setCrownDcmFile(injector);
            }
        },
        [setCrownDcmFile],
    );

    // UI input state
    const [selectedToothNumber, setSelectedToothNumber] = React.useState(10);
    const [jawVisibility, setJawVisibility] = React.useState<ScanReviewViewJawDisplayState>({
        upper: true,
        lower: true,
    });

    return (
        <StackY
            sx={{
                flex: 'auto',
                overflow: 'hidden',
                height: '95vh',
                backgroundColor: FlossPalette.WHITE,
            }}
        >
            <StackX>
                <File3dDropLoader scan={'Upper Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.UPPER, files, event)} />
                <File3dDropLoader scan={'Lower Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.LOWER, files, event)} />
                <File3dDropLoader scan={'Crown'} onDcmDrop={(files, event) => onCrownDcmDrop(files.at(0), event)} />
            </StackX>
            <StackX>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => setJawVisibility({ upper: e.target.checked, lower: jawVisibility.lower })}
                            checked={jawVisibility.upper}
                        />
                    }
                    label={'Upper:'}
                    labelPlacement={'start'}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => setJawVisibility({ lower: e.target.checked, upper: jawVisibility.upper })}
                            checked={jawVisibility.lower}
                        />
                    }
                    label={'Lower:'}
                    labelPlacement={'start'}
                />
                <FormControlLabel
                    style={{ width: '200px' }}
                    control={
                        <PoiRegroupDropdown
                            options={Array.from({ length: 32 }, (_, i) => i + 1).map(unn => ({
                                label: `${unn}`,
                                value: unn,
                            }))}
                            title={selectedToothNumber.toString()}
                            submit={setSelectedToothNumber}
                        />
                    }
                    label={'ToothNumber:'}
                    labelPlacement={'start'}
                />
            </StackX>
            <ToothPlacementVisualizationViewAppProvider
                upperJawFactory={upperJawTVFactory}
                lowerJawFactory={lowerJawTVFactory}
                crownFactory={crownStateFactory}
                toothNumber={selectedToothNumber as ToothNumber}
                viewState={initialViewState}
            >
                <ToothPlacementVisualizationView upper={jawVisibility.upper} lower={jawVisibility.lower} />
            </ToothPlacementVisualizationViewAppProvider>
        </StackY>
    );
};
