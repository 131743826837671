import { CreateDesignStaffTeamDialog } from './CreateDesignStaffTeamDialog';
import { useRosterInfoFormState } from './hooks/useRosterInfoFormState';
import type { LabsGqlDesignStaffDtoFragment, LabsGqlShiftScheduleStartEndFragment } from '@orthly/graphql-operations';
import { useGetDesignStaffTeamsQuery } from '@orthly/graphql-react';
import {
    LabsGqlDesignStaffEligibleCountry,
    LabsGqlDesignStaffEmploymentType,
    LabsGqlDesignStaffRegion,
    LabsGqlDesignStaffRole,
    LabsGqlOrderDesignCapabilityType,
} from '@orthly/graphql-schema';
import { LoadBlocker, SimpleSelect, SimpleAutocomplete } from '@orthly/ui';
import {
    CheckboxPrimitive as Checkbox,
    FormLabel,
    Grid,
    Slider,
    TextField,
    stylesFactory,
    Button,
    Text,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

type ScheduleDay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
const SCHEDULE_DAYS: ScheduleDay[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
const DAY_NAMES: Record<ScheduleDay, string> = {
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
};

const ROLE_NAMES: Record<LabsGqlDesignStaffRole, string> = {
    LEAD: 'Lead',
    TRAINER: 'Trainer',
    QUALITY: 'Quality',
    DESIGNER: 'Designer',
    QC: 'QC',
    PREP: 'Prep',
    TS: 'TS',
    SCAN_REVIEW: 'Scan Review',
    TREAMENT_PLANNER: 'Treatment Planner',
    PRINCIPLE_DESIGNER: 'Principal Designer',
    SCAN_PREP: 'Scan Prep',
    ANDON: 'Andon',
    RED_CARPET: 'Red Carpet',
    WAXRIM: 'Waxrim',
    MOLD: 'Mold',
    MODEL: 'Model',
    PFZ: 'PFZ',
    REFAB: 'Refab',
    AUTOMATE_DESIGNER: 'Automate Designer',
};

const QCSlider: React.FC<{
    setQCPercentRequired: (percentRequired: number) => void;
    percentRequiredState: number;
}> = ({ setQCPercentRequired, percentRequiredState }) => {
    return (
        <>
            <FormLabel>Percentage of Designs Required for QC</FormLabel>
            <Slider
                step={null}
                valueLabelDisplay={'auto'}
                marks={[0, 25, 50, 75, 100].map(n => ({ value: n, label: `${n}%` }))}
                value={percentRequiredState}
                valueLabelFormat={value => `${value}%`}
                onChange={(_event, value) => {
                    setQCPercentRequired(Number(value));
                }}
                size={'small'}
            />
        </>
    );
};

const TimePicker: React.VFC<{ label: string; enabled: boolean; value: string; setValue: React.Dispatch<string> }> = ({
    label,
    enabled,
    value,
    setValue,
}) => {
    const classes = useStyles();
    const displayValue = enabled ? value : '';

    return (
        <TextField
            variant={'standard'}
            id={'time'}
            label={label}
            type={'time'}
            value={displayValue}
            className={classes.textField}
            disabled={!enabled}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                step: 15 * 60, // 15 min
            }}
            onChange={evt => setValue(evt.currentTarget.value)}
        />
    );
};

const ScheduleRow: React.VFC<{
    day: string;
    schedule: LabsGqlShiftScheduleStartEndFragment;
    setScheduleDay: (scheduleDay: LabsGqlShiftScheduleStartEndFragment) => void;
}> = ({ day, schedule, setScheduleDay }) => {
    const enabled = !!schedule.start && !!schedule.end;

    // The actual schedule start and end get nulled out when the day is un-checked, but we
    // keep the previous values here so the data isn't lost if the user checks the box again.
    const [savedSchedule, setSavedSchedule] = React.useState(enabled ? schedule : { start: '08:00', end: '17:00' });

    const startTime = schedule.start ?? '';
    const endTime = schedule.end ?? '';

    return (
        <Grid item container alignItems={'center'}>
            <Grid item>
                <Checkbox
                    color={'secondary'}
                    checked={enabled}
                    onChange={evt => setScheduleDay(evt.target.checked ? savedSchedule : { start: null, end: null })}
                />
            </Grid>
            <Grid item xs={1}>
                <Text variant={'body2'}>{day}</Text>
            </Grid>
            <Grid item>
                <TimePicker
                    label={'Start'}
                    enabled={enabled}
                    value={startTime}
                    setValue={start => {
                        setScheduleDay({ ...schedule, start });
                        setSavedSchedule(s => ({ ...s, start }));
                    }}
                />
            </Grid>
            <Grid item>
                <TimePicker
                    label={'Stop'}
                    enabled={enabled}
                    value={endTime}
                    setValue={end => {
                        setScheduleDay({ ...schedule, end });
                        setSavedSchedule(s => ({ ...s, end }));
                    }}
                />
            </Grid>
        </Grid>
    );
};

// Keep in sync with mainCapabilitySchema in roster-data.types.ts
const MAIN_CAPABILITY_OPTIONS = [
    LabsGqlOrderDesignCapabilityType.Aligners,
    LabsGqlOrderDesignCapabilityType.AnteriorCrownAndBridge,
    LabsGqlOrderDesignCapabilityType.Dentures,
    LabsGqlOrderDesignCapabilityType.Implant,
    LabsGqlOrderDesignCapabilityType.Model,
    LabsGqlOrderDesignCapabilityType.Other,
    LabsGqlOrderDesignCapabilityType.Partials,
    LabsGqlOrderDesignCapabilityType.Pfm,
    LabsGqlOrderDesignCapabilityType.Pfz,
    LabsGqlOrderDesignCapabilityType.PosteriorCrownAndBridge,
    LabsGqlOrderDesignCapabilityType.RedCarpet,
    LabsGqlOrderDesignCapabilityType.Refabrication,
    LabsGqlOrderDesignCapabilityType.Removables,
    LabsGqlOrderDesignCapabilityType.Waxup,
];

export const EditUserDesignStaffRosterInfo: React.FC<{
    designerStaff: LabsGqlDesignStaffDtoFragment;
    refetchStaffCallback: () => Promise<any>;
}> = ({ designerStaff, refetchStaffCallback }) => {
    const { submit, submitting, hasEdited, formState, setFormState } = useRosterInfoFormState(
        designerStaff,
        refetchStaffCallback,
    );
    const { data: designerStaffTeams, refetch: refetchTeamsCallback } = useGetDesignStaffTeamsQuery();

    const {
        country,
        design_staff_qc_percentage_required,
        design_staff_region,
        design_staff_role,
        design_staff_team_id,
        ordered_main_capabilities,
        employment_type,
    } = formState;

    const teams = designerStaffTeams?.getDesignStaffTeams ?? [];

    const setScheduleDay = (day: ScheduleDay, dayStartEnd: LabsGqlShiftScheduleStartEndFragment | null) => {
        setFormState({ ...formState, schedule: { ...formState.schedule, [day]: dayStartEnd } });
    };

    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <Text variant={'h6'}>Roster Info</Text>
            </Grid>
            <Grid container spacing={3} item direction={'row'}>
                <Grid item container spacing={2} direction={'column'} xs={6}>
                    <Grid item>
                        <SimpleSelect
                            label={'Country'}
                            SelectProps={{ style: { width: '100%' }, variant: 'standard' }}
                            value={country ?? undefined}
                            onChange={value => {
                                setFormState({
                                    ...formState,
                                    country:
                                        Object.values(LabsGqlDesignStaffEligibleCountry).find(c => c === value) ?? null,
                                });
                            }}
                            options={Object.values(LabsGqlDesignStaffEligibleCountry).map(c => ({
                                label: c.split('_').map(_.startCase).join(' '),
                                value: c,
                            }))}
                        />
                    </Grid>
                    <Grid item>
                        <SimpleSelect
                            SelectProps={{ style: { width: '100%' }, variant: 'standard' }}
                            value={design_staff_region ?? undefined}
                            label={'Region'}
                            onChange={value => {
                                setFormState({
                                    ...formState,
                                    design_staff_region:
                                        Object.values(LabsGqlDesignStaffRegion).find(r => r === value) ?? null,
                                });
                            }}
                            options={Object.values(LabsGqlDesignStaffRegion).map(r => ({ label: r, value: r }))}
                        />
                    </Grid>
                    <Grid item>
                        <SimpleSelect
                            SelectProps={{ style: { width: '100%' }, variant: 'standard' }}
                            value={design_staff_role ?? undefined}
                            label={'Role'}
                            onChange={value => {
                                setFormState({
                                    ...formState,
                                    design_staff_role:
                                        Object.values(LabsGqlDesignStaffRole).find(r => r === value) ?? null,
                                });
                            }}
                            options={Object.values(LabsGqlDesignStaffRole).map(r => ({
                                label: ROLE_NAMES[r],
                                value: r,
                            }))}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2} direction={'column'} xs={6}>
                    <Grid item>
                        <SimpleSelect
                            SelectProps={{ style: { width: '100%' }, variant: 'standard' }}
                            value={employment_type ?? undefined}
                            label={'Employment Type'}
                            onChange={value => {
                                setFormState({
                                    ...formState,
                                    employment_type:
                                        Object.values(LabsGqlDesignStaffEmploymentType).find(r => r === value) ?? null,
                                });
                            }}
                            options={Object.values(LabsGqlDesignStaffEmploymentType).map(r => ({
                                label: r.split('_').map(_.startCase).join(' '),
                                value: r,
                            }))}
                        />
                    </Grid>

                    <Grid item>
                        <SimpleSelect
                            SelectProps={{ style: { width: '100%' }, variant: 'standard' }}
                            value={_.first(ordered_main_capabilities)}
                            label={'Main Capability'}
                            onChange={value => {
                                const newValue = MAIN_CAPABILITY_OPTIONS.find(r => r === value);
                                setFormState({
                                    ...formState,
                                    ordered_main_capabilities: newValue ? [newValue] : null,
                                });
                            }}
                            options={MAIN_CAPABILITY_OPTIONS.map(c => ({
                                label: c.split('_').map(_.startCase).join(' '),
                                value: c,
                            }))}
                        />
                    </Grid>
                    <Grid item>
                        <QCSlider
                            setQCPercentRequired={percentRequired => {
                                setFormState({ ...formState, design_staff_qc_percentage_required: percentRequired });
                            }}
                            percentRequiredState={design_staff_qc_percentage_required}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} item direction={'row'}>
                    <Grid item container spacing={2} direction={'column'} xs={6}>
                        <Grid item>
                            <SimpleSelect
                                SelectProps={{ style: { width: '100%' }, variant: 'standard' }}
                                label={'Team'}
                                value={design_staff_team_id ?? undefined}
                                onChange={value =>
                                    setFormState({
                                        ...formState,
                                        design_staff_team_id: teams.find(t => t.id === value)?.id ?? null,
                                    })
                                }
                                options={_.sortBy(teams).map(t => ({ label: t.name, value: t.id }))}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction={'column'} xs={2} justifyContent={'center'}>
                        <CreateDesignStaffTeamDialog
                            designerStaffTeams={designerStaffTeams}
                            refetchTeamsCallback={refetchTeamsCallback}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container spacing={1}>
                <Grid item xs={12}>
                    <Text variant={'body1'}>Shift schedule</Text>
                </Grid>
                <Grid item xs={4}>
                    <SimpleAutocomplete
                        label={'Time zone'}
                        initialInputValue={formState.schedule.timezone ?? undefined}
                        onChange={value =>
                            setFormState({ ...formState, schedule: { ...formState.schedule, timezone: value ?? null } })
                        }
                        options={Intl.supportedValuesOf('timeZone')}
                    />
                </Grid>

                {SCHEDULE_DAYS.map(day => (
                    <ScheduleRow
                        key={day}
                        day={DAY_NAMES[day]}
                        schedule={formState.schedule[day]}
                        setScheduleDay={sd => setScheduleDay(day, sd)}
                    />
                ))}
            </Grid>
            <Grid item>
                <LoadBlocker blocking={submitting}>
                    <Button
                        startIcon={'Save'}
                        onClick={async () => {
                            await submit(formState);
                        }}
                        variant={'primary'}
                        disabled={!hasEdited}
                    >
                        Save Roster Info
                    </Button>
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};
