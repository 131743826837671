import { AnnotationListModal } from '../Annotations';
import { INITIAL_TOOL_STATE } from '../Annotations/DrawingControls';
import type { GuidedWaxupViewerWrapperProps } from './DesignViewerLiteDetachedWrapper';
import { DesignViewerLiteDetachedWrapper } from './DesignViewerLiteDetachedWrapper';
import { GuidedWaxupAnnotationButtons } from './GuidedWaxups/components/GuidedWaxupAnnotationButtons';
import { GuidedWaxupDrawingControls } from './GuidedWaxups/components/GuidedWaxupDrawingControls';
import { GuidedWaxupModelCanvasButtons } from './GuidedWaxups/components/GuidedWaxupModelCanvasButtons';
import { useGuidedWaxupContext } from './GuidedWaxups/state/GuidedWaxupContext';
import { useGuidedWaxupSelector, useWaxupIsRejected } from './GuidedWaxups/state/GuidedWaxupState';
import {
    useGetAnnotationCanvasFunctions,
    useGetCommonAnnotationState,
    useUploadCurrentAnnotatedImage,
} from './Waxup.util.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { useDrawingCanvas, useZoomToVisible } from '@orthly/dentin';
import { getFragmentData, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
    LabsGqlGuidedWaxupPresetType,
} from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, stylesFactory, Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

type ViewerState = 'uploading' | 'annotating-screenshot' | 'viewing-model';

const useStyles = stylesFactory<{
    viewerState: ViewerState;
    canvasSize: { width: number; height: number };
    selectedDesignRevisionAlreadyReviewed: boolean;
    showDrawingControls: boolean;
}>(theme => ({
    root: {
        position: 'relative',
    },
    wrapperContainer: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: ({ showDrawingControls }) => (showDrawingControls ? '16px 16px 0 0' : '16px'),
        [theme.breakpoints.down('lg')]: {
            '&&': {
                borderRadius: 16,
                margin: '16px 12px',
            },
        },
    },
    canvas: {
        borderRadius: '16px 16px 0 0',
        overflow: 'hidden',
        backgroundColor: FlossPalette.DARK_TAN,
    },
    canvasAndWrapperContainer: {
        position: 'relative',
        display: ({ viewerState }) => (viewerState === 'viewing-model' ? 'block' : 'none'),
    },
    drawControlsAndAnnotationContainer: {
        position: 'relative',
        display: ({ viewerState }) => (viewerState === 'annotating-screenshot' ? 'block' : 'none'),
    },
    blockerContainer: {
        display: ({ viewerState }) => (viewerState === 'uploading' ? 'block' : 'none'),
        backgroundColor: FlossPalette.DARK_TAN,
    },
    blocker: {
        minWidth: ({ canvasSize }) => canvasSize.width,
        minHeight: ({ canvasSize }) => canvasSize.height,
        width: '100%',
    },
    designStatusContainer: {
        position: 'absolute',
        top: 10,
        left: 10,
        padding: '0px 10px',
        background: ({ selectedDesignRevisionAlreadyReviewed }) =>
            selectedDesignRevisionAlreadyReviewed ? 'transparent' : FlossPalette.BLACK,
        zIndex: 2,
        borderRadius: 4,
    },
}));

export const trackGuidedWaxupButtonClick = (buttonText: string) => {
    BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
        AssetName: `Guided Waxup Button - ${buttonText}`,
        AssetType: 'button',
        AssetVersion: '',
        AssetCTAText: buttonText,
    });
};

// eslint-disable-next-line  max-lines-per-function
export const DesignViewerLiteDetachedContainer: React.VFC<GuidedWaxupViewerWrapperProps> = props => {
    const { appearance, controlRef, setPresetAnnotation } = props;
    const {
        order,
        selectedTab,
        selectedDesignRevisionAlreadyReviewed,
        revisionId,
        selectedDesignFragment,
        previousDesignFragment,
        setIsAnnotatingScreenshot,
        enableGuidedWaxupComparison,
        isImmediateDenture,
        resetView,
    } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const selectedPresetStatus = useGuidedWaxupSelector(s => s.presets[selectedTab]?.status);
    const presetRejected = selectedPresetStatus === LabsGqlGuidedWaxupPresetStatus.Rejected;
    const waxupRejected = useWaxupIsRejected();
    const generalViewRejected = selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView && waxupRejected;
    const zoomToVisible = useZoomToVisible(controlRef, appearance);
    const annotatedImageUrls = useGuidedWaxupSelector(s => s.presets[selectedTab]?.annotatedImageUrls);
    const annotationModalUrls =
        annotatedImageUrls?.map(url => ({
            imageUrl: url,
        })) ?? [];

    const selectedDesign = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, selectedDesignFragment);
    const isApprovedWaxup = selectedDesign?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;
    const {
        takeSnapshotRef,
        isAnnotationListModalOpen,
        setIsAnnotationListModalOpen,
        screenshotToAnnotate,
        setScreenshotToAnnotate,
        isUploading,
        setIsUploading,
        canvasSize,
        setCanvasSize,
        backgroundImageUrl,
    } = useGetCommonAnnotationState();

    const backgroundImageSizeCallback = React.useCallback(
        (width, height) => {
            setCanvasSize({ width, height });
        },
        [setCanvasSize],
    );

    const { canvas, toolState, setToolState, takeSnapshot, undoState, undo, redo, clear } = useDrawingCanvas({
        ...canvasSize,
        initialToolState: INITIAL_TOOL_STATE,
        backgroundImageSizeCallback,
        backgroundImageUrl: backgroundImageUrl ?? undefined,
        backgroundColor: FlossPalette.DARK_TAN,
        canvasProps: { style: { border: 'unset' } },
    });

    const { handleTakeScreenshot, setToolStateWrapped, closeDrawingCanvas } = useGetAnnotationCanvasFunctions(
        order.id,
        takeSnapshotRef,
        screenshotToAnnotate,
        setScreenshotToAnnotate,
        clear,
        setToolState,
    );

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const viewerState: ViewerState = isUploading
        ? 'uploading'
        : screenshotToAnnotate
          ? 'annotating-screenshot'
          : 'viewing-model';

    const uploadCurrentAnnotatedImage = useUploadCurrentAnnotatedImage(takeSnapshot, props.order.id);

    // Handler for the "Save and markup new screen capture" button.
    // Uploads the current annotation and adds it to `dataToSubmit`.
    const saveAnnotatedImage = React.useCallback(async () => {
        try {
            closeDrawingCanvas();
            setIsUploading(true);
            const annotatedImageUrl = await uploadCurrentAnnotatedImage();
            if (annotatedImageUrl) {
                setPresetAnnotation?.([...(annotatedImageUrls ?? []), annotatedImageUrl]);
            }
        } finally {
            setIsUploading(false);
        }
    }, [uploadCurrentAnnotatedImage, closeDrawingCanvas, setIsUploading, annotatedImageUrls, setPresetAnnotation]);

    /*
     * This conditional determines whether to show the Markup tool button within the model viewer for mobile
     * We check to make sure we are on mobile, that the doctor isn't already currently marking up a screenshot or uploading one,
     * that the doctor isn't viewing a historical design that has already been approved/rejected, and that the current preset they
     * are viewing has been rejected since we only allow doctors to provide annotations on rejection
     */
    const displayMobileMarkupButton =
        isMobile &&
        viewerState === 'viewing-model' &&
        !selectedDesignRevisionAlreadyReviewed &&
        revisionId === undefined &&
        (presetRejected || generalViewRejected);

    // This conditional determines to show the annotation tools, which is only shown if the preset is rejected and we're viewing a current waxup
    // This is also visible deterministically in the mobile view by the viewer state, but that is checked below where it is rendered
    const showDrawingControls = (presetRejected || generalViewRejected) && !selectedDesignRevisionAlreadyReviewed;

    const showDesignStatus = enableGuidedWaxupComparison && viewerState === 'viewing-model' && !isApprovedWaxup;

    const classes = useStyles({ viewerState, canvasSize, selectedDesignRevisionAlreadyReviewed, showDrawingControls });

    React.useEffect(() => {
        if (selectedPresetStatus === LabsGqlGuidedWaxupPresetStatus.Approved) {
            closeDrawingCanvas();
        }
    }, [selectedPresetStatus, closeDrawingCanvas]);

    React.useEffect(() => {
        if (viewerState !== 'viewing-model') {
            setIsAnnotatingScreenshot(true);
        } else {
            setIsAnnotatingScreenshot(false);
        }
    }, [viewerState, setIsAnnotatingScreenshot]);

    return (
        <div className={classes.root}>
            {showDesignStatus && (
                <div className={classes.designStatusContainer}>
                    <Text variant={'body2'} color={selectedDesignRevisionAlreadyReviewed ? 'GRAY' : 'WHITE'} medium>
                        {selectedDesignRevisionAlreadyReviewed ? 'Archived design' : 'Needs Review'}
                    </Text>
                </div>
            )}
            <div className={classes.canvasAndWrapperContainer}>
                <GuidedWaxupModelCanvasButtons
                    setIsAnnotationListModalOpen={setIsAnnotationListModalOpen}
                    markupButtonClick={() => {
                        trackGuidedWaxupButtonClick('Markup');
                        clear();
                        void handleTakeScreenshot();
                    }}
                    displayMobileMarkupButton={displayMobileMarkupButton}
                    zoomToVisButtonClick={() => {
                        trackGuidedWaxupButtonClick('Zoom To Fit');
                        zoomToVisible();
                    }}
                    annotatedImageUrls={annotatedImageUrls}
                    resetCameraView={() => {
                        trackGuidedWaxupButtonClick('Reset camera view');
                        resetView();
                    }}
                />
                <div className={classes.wrapperContainer}>
                    <DesignViewerLiteDetachedWrapper
                        takeSnapshotRef={takeSnapshotRef}
                        designFragment={selectedDesignFragment}
                        previousDesignFragment={previousDesignFragment}
                        isImmediateDenture={isImmediateDenture}
                        {...props}
                    />
                </div>
            </div>
            <div className={classes.drawControlsAndAnnotationContainer}>
                <div className={classes.canvas}>{canvas}</div>
                {isMobile && showDrawingControls && (
                    <GuidedWaxupDrawingControls
                        toolState={toolState}
                        setToolState={setToolStateWrapped}
                        canUndo={undoState.canUndo}
                        canRedo={undoState.canRedo}
                        undo={undo}
                        redo={redo}
                        capturedScreenshotCount={annotatedImageUrls?.length ?? 0}
                        setIsAnnotationListModalOpen={setIsAnnotationListModalOpen}
                    />
                )}
                {(presetRejected || generalViewRejected) && (
                    <GuidedWaxupAnnotationButtons
                        closeDrawingCanvas={closeDrawingCanvas}
                        saveAnnotatedImage={saveAnnotatedImage}
                        trackGuidedWaxupButtonClick={trackGuidedWaxupButtonClick}
                    />
                )}
            </div>
            {!isMobile && showDrawingControls && (
                <GuidedWaxupDrawingControls
                    toolState={toolState}
                    setToolState={setToolStateWrapped}
                    canUndo={undoState.canUndo}
                    canRedo={undoState.canRedo}
                    undo={undo}
                    redo={redo}
                    capturedScreenshotCount={annotatedImageUrls?.length ?? 0}
                    setIsAnnotationListModalOpen={setIsAnnotationListModalOpen}
                />
            )}
            <div className={classes.blockerContainer}>
                <LoadBlocker blocking={true}>
                    <div className={classes.blocker} />
                </LoadBlocker>
            </div>
            <AnnotationListModal
                annotations={annotationModalUrls}
                open={isAnnotationListModalOpen}
                setOpen={setIsAnnotationListModalOpen}
                canRemove={true}
                onRemove={indexToRemove => {
                    const newAnnotationsList = annotationModalUrls.filter((_, i) => i !== indexToRemove);
                    if (newAnnotationsList.length === 0) {
                        setIsAnnotationListModalOpen(false);
                    }
                    setPresetAnnotation?.(newAnnotationsList.map(url => url.imageUrl));
                }}
                dialogProps={{
                    maxWidth: 'md',
                }}
            />
        </div>
    );
};
