import { useOrdersListViewIds } from '../../state/OrdersListViewIdsProvider';
import { useOrdersOverviewAction, OrdersOverviewActions } from '../../state/OrdersOverview.actions';
import { useOrdersOverviewState } from '../../state/OrdersOverview.selectors';
import type { OrdersOverviewScreen } from '../../state/OrdersOverview.types';
import { SaveOrdersViewModal } from '../SavedViews/OrderSavedViews';
import { useActiveOrdersViewIsEdited, useOrdersCustomViewSidebarEntries } from './OrdersCustomViewNavItem';
import { useApolloClient } from '@apollo/client';
import { LabsGqlCustomSearchType, LabsGqlLabOrderStatus as OrderStatus } from '@orthly/graphql-schema';
import { useAsyncIsLoading } from '@orthly/redux-async-actions';
import type { SubNavigationEntry } from '@orthly/ui';
import { SubNavigation } from '@orthly/ui';
import { stylesFactory, IconButton, RefreshIcon } from '@orthly/ui-primitives';
import { SavedViewsAutocomplete } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    list: { width: '100%', bottom: 80, top: 64, position: 'absolute', overflow: 'auto' },
    bottomContainer: { position: 'absolute', bottom: 16, padding: 16 },
}));

const mainScreenSections: SubNavigationEntry<OrdersOverviewScreen>[][] = [
    [
        { value: 'scans', label: 'Unsubmitted scans' },
        { value: OrderStatus.NeedsReview, label: 'Needs Review' },
        { value: 'waxups', label: 'Design Preview Review' },
        { value: OrderStatus.OnHold, label: 'On hold' },
    ],
    [
        { value: OrderStatus.New, label: 'New' },
        { value: OrderStatus.Fabrication, label: 'Fabrication' },
        { value: OrderStatus.Shipped, label: 'Shipped' },
    ],
    [
        { value: OrderStatus.Delivered, label: 'Delivered' },
        { value: OrderStatus.Cancelled, label: 'Canceled' },
        { value: 'all', label: 'All' },
    ],
    [{ value: 'returns', label: 'Returns' }],
];

export const OrdersSubNavigation: React.FC = () => {
    const { refetch, loading: idsLoading } = useOrdersListViewIds();
    const classes = useStyles();
    const client = useApolloClient();
    const screen = useOrdersOverviewState(s => (s.screen === 'custom' ? s.view.id : s.screen));
    const activeViewEdited = useActiveOrdersViewIsEdited();
    const setScreen = useOrdersOverviewAction('SET_SCREEN');
    const toggleFavorite = useOrdersOverviewAction('TOGGLE_FAVORITE_VIEW');
    const onScreenChange = React.useCallback(
        (newScreen: OrdersOverviewScreen | string) => {
            if (screen !== newScreen && (!activeViewEdited || window.confirm(`Abandon changes to current view?`))) {
                setScreen(newScreen);
            }
        },
        [activeViewEdited, setScreen, screen],
    );
    const customViewEntries = useOrdersCustomViewSidebarEntries();
    const viewsLoading = useAsyncIsLoading(OrdersOverviewActions.LOAD_VIEWS.NAME);
    const loadViews = useOrdersOverviewAction('LOAD_VIEWS');

    return (
        <>
            <SubNavigation<any>
                title={'Orders'}
                classes={{ list: classes.list, bottomContainer: classes.bottomContainer }}
                titleAction={
                    <IconButton
                        role={'button'}
                        aria-label={'Refresh orders'}
                        onClick={async () => {
                            await refetch();
                            await loadViews({ client });
                        }}
                        disabled={idsLoading || viewsLoading}
                        size={'small'}
                    >
                        <RefreshIcon />
                    </IconButton>
                }
                value={screen}
                onChange={onScreenChange}
                sections={[...mainScreenSections, customViewEntries]}
                bottomContent={
                    <SavedViewsAutocomplete
                        onSelect={async value => await toggleFavorite({ client, searchId: value, favorited: true })}
                        type={LabsGqlCustomSearchType.Order}
                    />
                }
            />
            <SaveOrdersViewModal />
        </>
    );
};
