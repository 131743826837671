import { useIsRejectionNoteRequired } from '../../GuidedWaxup.util';
import { Text, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const RejectionNotesContainer: React.FC<{
    title: React.ReactNode;
}> = ({ children, title }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const isRequired = useIsRejectionNoteRequired();

    return (
        <Grid item container direction={'column'}>
            <Grid item container justifyContent={'space-between'}>
                <Grid item>
                    <Text variant={isMobile ? 'body2' : 'body1'} medium>
                        {title}
                    </Text>
                </Grid>
                <Grid item>
                    <Text variant={'body2'} color={isRequired ? 'ATTENTION_FOREGROUND' : 'BLACK'}>
                        {isRequired ? 'Required field' : 'Optional'}
                    </Text>
                </Grid>
            </Grid>
            <Grid item sx={{ marginTop: '8px' }}>
                {children}
            </Grid>
        </Grid>
    );
};
