import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import { useManufacturerIntegrations } from '../../../../../../../utils/useManufacturerIntegrations';
import { useOrderDetailContext } from '../../../state/OrderDetailProvider.graphql';
import { PlaceOrderOnHoldToolbarActionDef } from './AddOrderHoldAction';
import { CancelOrderToolbarAction } from './CancelOrderToolbarAction.graphql';
import { ConvertToArchformToolbarAction } from './ConvertToArchformToolbarAction';
import { FlagForReviewToolbarAction } from './FlagForReviewToolbarAction';
import { LinkOrderToRefabToolbarAction } from './LinkOrderToRefabToolbarAction';
import { MarkDeliveredToolbarAction } from './MarkDeliveredToolbarAction';
import { MarkInFabricationToolbarAction } from './MarkInFabrication';
import { MarkNeedsRefabricationToolbarActionDef } from './MarkNeedsRefabrication';
import { MarkRefabReturnReceivedToolbarAction } from './MarkRefabReturnReceivedToolbarAction';
import { MarkShippedToolbarAction } from './MarkShippedToolbarAction';
import { NonProdQuickMarkDeliveredActionToolbarAction } from './NonProdQuickMarkDeliveredAction';
import { RecomputeOrderToolbarAction } from './RecomputeOrderToolbarAction';
import { RemoveOrderOnHoldToolbarActionDef } from './RemoveOrderHoldAction.graphql';
import { SendOrderToQaEvaluationToolbarAction } from './SendOrderToQaEvaluationToolbarAction.graphql';
import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { usePendingReturnForOrderQuery } from '@orthly/graphql-react';
import type { LabsGqlManufacturerIntegration } from '@orthly/graphql-schema';
import { LabsGqlIntegrationOrderIoCapability } from '@orthly/graphql-schema';
import { doesStaffHaveCapability } from '@orthly/retainer-common';
import { UuidUtils } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import type { ButtonProps } from '@orthly/ui';
import { Button, Menu, MenuItem, Tooltip } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface OrderDetailStatusActionsProps {}

function useGetVisibleActions(order: LabsGqlOrder | undefined): ToolbarActionDefinition[] {
    const { value: isDesignQaEnabled } = useFeatureFlag('designQaAllowedToAddToQueue');
    const session = useSession();

    const { data, loading: returnLoading } = usePendingReturnForOrderQuery({
        skip: !UuidUtils.isUUID(order?.id),
        variables: {
            orderId: order?.id ?? '',
        },
    });

    const linkedReturnId = !returnLoading && data?.pendingReturnForOrder?.id;

    return React.useMemo<ToolbarActionDefinition[]>(() => {
        const ActionDefinitions: ToolbarActionDefinition[] = _.compact([
            FlagForReviewToolbarAction,
            MarkInFabricationToolbarAction,
            PlaceOrderOnHoldToolbarActionDef,
            RemoveOrderOnHoldToolbarActionDef,
            MarkShippedToolbarAction,
            MarkDeliveredToolbarAction,
            MarkNeedsRefabricationToolbarActionDef,
            LinkOrderToRefabToolbarAction,
            CancelOrderToolbarAction,
            ConvertToArchformToolbarAction,
            // Only visible in dev environments. Controlled by `isVisible`.
            RecomputeOrderToolbarAction,
            NonProdQuickMarkDeliveredActionToolbarAction,
            isDesignQaEnabled ? SendOrderToQaEvaluationToolbarAction : undefined,
            linkedReturnId ? MarkRefabReturnReceivedToolbarAction : undefined,
        ]);

        if (!order) {
            return [];
        }
        return ActionDefinitions.filter(action => {
            if (action.allowedStatuses && !action.allowedStatuses.includes(order.status)) {
                return false;
            }

            if (action.disallowedStatuses && action.disallowedStatuses.includes(order.status)) {
                return false;
            }

            if (
                action.capabilityRequirement &&
                !doesStaffHaveCapability(session, 'order', action.capabilityRequirement)
            ) {
                return false;
            }

            return action.isVisible ? action.isVisible(order) : true;
        });
    }, [isDesignQaEnabled, order, linkedReturnId, session]);
}

const CustomButton: React.FC<ButtonProps> = () => {
    return null;
};

interface ToolbarActionButtonProps {
    actionDefinition: ToolbarActionDefinition;
    manufacturerIntegrations: _.Dictionary<LabsGqlManufacturerIntegration | undefined>;
    order: LabsGqlOrder;
    onClick: (label: string) => void;
}

/**
 * Renders the MenuItem button for the dropdown menu containing the Toolbar Action.
 *
 * @description The button is disabled if the order manufacturer_id is one that is "integrated" with Dandy and the action is
 * MarkInFabricationToolbarAction. "Integrated" is defined as having LabsGqlIntegrationOrderIoCapability.ReadWrite
 * on the order_io_capability column of excavator.manufacturer_integration_snapshot
 * @param actionDefinition Toolbar Action to be rendered
 * @param manufacturerIntegrations Key-Value pair of manufacturer_id to properties - result of useManufacturerIntegrations()
 * @param order The LabOrder for which to render the Toolbar Action
 * @param onClick Function to run when the button is clicked
 * @link https://meetdandy.atlassian.net/browse/EPDSCM-71
 */
const ToolbarActionButton: React.VFC<ToolbarActionButtonProps> = ({
    actionDefinition,
    manufacturerIntegrations,
    order,
    onClick,
}) => {
    const { label, renderLabel } = actionDefinition;
    const isDandyIntegratedLab =
        manufacturerIntegrations[order.manufacturer_id]?.order_io_capability ===
        LabsGqlIntegrationOrderIoCapability.ReadWrite;
    if (label === MarkInFabricationToolbarAction.label && isDandyIntegratedLab) {
        return (
            <Tooltip title={'Cannot move orders into fabrication for labs that have LMS integrations'}>
                <span>
                    <MenuItem disabled={true}>{renderLabel ? renderLabel(order) : label}</MenuItem>
                </span>
            </Tooltip>
        );
    }
    return (
        <MenuItem
            onClick={() => {
                onClick(label);
            }}
        >
            {renderLabel ? renderLabel(order) : label}
        </MenuItem>
    );
};

export const OrderDetailStatusActions: React.VFC<OrderDetailStatusActionsProps> = () => {
    const { order, refetch } = useOrderDetailContext();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [openAction, setOpenAction] = React.useState<string | undefined>();
    const visibleActions = useGetVisibleActions(order);
    const OpenActionComponent = React.useMemo<React.ComponentType<ToolbarActionProps> | undefined>(() => {
        return !openAction ? undefined : visibleActions.find(a => a.label === openAction)?.Component;
    }, [openAction, visibleActions]);

    const manufacturerIntegrations: _.Dictionary<LabsGqlManufacturerIntegration | undefined> =
        useManufacturerIntegrations();

    if (visibleActions.length === 0 || !order) {
        return null;
    }
    return (
        <>
            <Button
                disabled={!order || visibleActions.length === 0}
                variant={'ghost'}
                endIcon={'KeyboardArrowDownIcon'}
                onClick={e => setAnchorEl(e.currentTarget)}
            >
                Edit status
            </Button>
            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {visibleActions.map(actionDef => (
                    <ToolbarActionButton
                        key={actionDef.label}
                        actionDefinition={actionDef}
                        manufacturerIntegrations={manufacturerIntegrations}
                        order={order}
                        onClick={(label: string) => {
                            setOpenAction(label);
                            setAnchorEl(null);
                            if (label === CancelOrderToolbarAction.label) {
                                AnalyticsClient.track('All - Cancel Order - Opened', {
                                    $groups: { order: order.id },
                                });
                            }
                        }}
                    />
                ))}
            </Menu>
            {!!OpenActionComponent && (
                <OpenActionComponent
                    open={true}
                    setOpen={() => setOpenAction(undefined)}
                    refetchOrder={refetch}
                    order={order}
                    CustomButton={CustomButton}
                />
            )}
        </>
    );
};
