import { useShareScansEmailValidation } from './useShareScansEmailValidation';
import { useShareScansPhoneNumberValidation } from './useShareScansPhoneNumberValidation';
import { SimplePhoneInput } from '@orthly/ui';
import { Box, Text, TextField, FlossPalette, Radio, FormControl, Select, MenuItem } from '@orthly/ui-primitives';
import React from 'react';

interface ShareScansWithPatientModalShareMethodProps {
    linkOrPrintout: string;
    setLinkOrPrintout: (linkOrPrintout: string) => void;
    patientMobilePhone: string | null | undefined;
    setPatientMobilePhone: (patientMobilePhone: string | undefined) => void;
    patientEmail: string | null | undefined;
    setPatientEmail: (patientEmail: string | undefined) => void;
    disablePdf: boolean;
}

export const ShareScansWithPatientModalShareMethod: React.FC<ShareScansWithPatientModalShareMethodProps> = ({
    linkOrPrintout,
    setLinkOrPrintout,
    patientMobilePhone,
    setPatientMobilePhone,
    patientEmail,
    setPatientEmail,
    disablePdf,
}) => {
    const [smsOrEmail, setSmsOrEmail] = React.useState(patientEmail ? 'email' : 'sms');
    const { emailError } = useShareScansEmailValidation(patientEmail ?? '');
    const { phoneNumberError } = useShareScansPhoneNumberValidation(patientMobilePhone ?? '');

    const resetEmailAndPhoneNumber = () => {
        setPatientEmail(undefined);
        setPatientMobilePhone(undefined);
    };

    return (
        <Box style={{ paddingLeft: '24px' }}>
            <Text variant={'body2'} medium color={'BLACK'} sx={{ marginBottom: '16px' }}>
                Share method
            </Text>
            <Box>
                <Box
                    sx={{
                        border: `1px solid ${FlossPalette.DARK_TAN}`,
                        borderRadius: '8px',
                        backgroundColor: FlossPalette.TAN,
                        padding: '16px',
                        marginBottom: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box>
                            <Radio
                                checked={linkOrPrintout === 'smsOrEmail'}
                                onChange={e => {
                                    resetEmailAndPhoneNumber();
                                    setLinkOrPrintout(e.target.value);
                                }}
                                value={'smsOrEmail'}
                                style={{ margin: 0 }}
                                data-test={'smsOrEmail-radio'}
                            />
                        </Box>
                        <Box sx={{ marginLeft: '16px' }}>
                            <Text variant={'body2'} color={'BLACK'} medium>
                                Send a link
                            </Text>
                            <Text variant={'body2'} color={'GRAY'}>
                                Via SMS or Email
                            </Text>
                        </Box>
                    </Box>
                    {linkOrPrintout === 'smsOrEmail' && (
                        <Box
                            sx={{
                                display: 'flex',
                                marginTop: '16px',
                            }}
                        >
                            <Box sx={{ width: '100px' }}>
                                <FormControl fullWidth>
                                    <Select
                                        style={{
                                            borderRadius: '8px 0 0 8px',
                                            fontWeight: 'bold',
                                            paddingBottom: '23px',
                                            height: '61px',
                                        }}
                                        data-test={'smsOrEmail-select'}
                                        value={smsOrEmail}
                                        onChange={e => {
                                            resetEmailAndPhoneNumber();
                                            setSmsOrEmail(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={'sms'}>SMS</MenuItem>
                                        <MenuItem value={'email'}>Email</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                {smsOrEmail === 'sms' && (
                                    <SimplePhoneInput
                                        label={'Phone number'}
                                        data-test={'phone-number-input'}
                                        fullWidth
                                        variant={'standard'}
                                        value={patientMobilePhone ?? ''}
                                        onChange={value => setPatientMobilePhone(value ?? '')}
                                        TextFieldProps={{
                                            type: 'tel',
                                            error: !!phoneNumberError,
                                            helperText: phoneNumberError,
                                            InputProps: {
                                                style: {
                                                    borderRadius: '0 8px 8px 0',
                                                },
                                            },
                                        }}
                                    />
                                )}
                                {smsOrEmail === 'email' && (
                                    <TextField
                                        fullWidth
                                        variant={'standard'}
                                        label={'Email address'}
                                        data-test={'email-input'}
                                        value={patientEmail}
                                        onChange={event => setPatientEmail(event.target.value)}
                                        type={'email'}
                                        helperText={patientEmail && emailError}
                                        error={Boolean(patientEmail && emailError)}
                                        InputProps={{
                                            style: {
                                                borderRadius: '0 8px 8px 0',
                                            },
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    border: `1px solid ${FlossPalette.DARK_TAN}`,
                    borderRadius: '8px',
                    backgroundColor: FlossPalette.TAN,
                    display: 'flex',
                    padding: '16px',
                }}
            >
                <Box>
                    <Radio
                        checked={linkOrPrintout === 'printout'}
                        onChange={e => {
                            resetEmailAndPhoneNumber();
                            setLinkOrPrintout(e.target.value);
                        }}
                        value={'printout'}
                        style={{ margin: 0 }}
                        data-test={'printout-radio'}
                        disabled={disablePdf}
                    />
                </Box>
                <Box sx={{ marginLeft: '16px' }}>
                    <Text variant={'body2'} color={'BLACK'} medium sx={{ opacity: disablePdf ? '40%' : '100%' }}>
                        Printout
                    </Text>
                    <Text variant={'body2'} color={'GRAY'}>
                        Print a PDF to give to the patient
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};
