import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useListLabOrgsWithoutRelations } from '@orthly/graphql-react';
import { DANDY_INTERNAL_ID } from '@orthly/retainer-common';
import { RootActionDialog, SimpleSelect, useRootActionCommand } from '@orthly/ui';
import { Button, Grid, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const NO_OVERRIDE_VALUE = '__NO_OVERRIDE';
const NO_OVERRIDE_OPTION = { label: 'Do Not Manually Override', value: NO_OVERRIDE_VALUE };
const INTERNAL_DESIGN_VALUE = '__INTERNAL_DESIGN';
const INTERNAL_DESIGN_OPTION = { label: 'Internal Design', value: INTERNAL_DESIGN_VALUE };
const EXTERNAL_DESIGN_VALUE = '__EXTERNAL_DESIGN';
const EXTERNAL_DESIGN_OPTION = { label: 'External Design', value: EXTERNAL_DESIGN_VALUE };

const AdminProductionPlanManualAssignmentsActionSetProductionPlanManualAssignments_Mutation = graphql(`
    mutation AdminProductionPlanManualAssignmentActionSetProductionPlanManualAssignments_Mutation(
        $args: ProductionPlanSetManualAssignmentInput!
    ) {
        setProductionPlanManualAssignments(args: $args) {
            id
        }
    }
`);

interface ProductionPlanManualAssignmentsActionProps {
    productionOrderId: string;
    onSuccess: () => Promise<unknown>;
}

export const ProductionPlanManualAssignmentsAction: React.FC<ProductionPlanManualAssignmentsActionProps> = ({
    productionOrderId,
    onSuccess,
}) => {
    const setManualAssignmentsMutation = useMutation(
        AdminProductionPlanManualAssignmentsActionSetProductionPlanManualAssignments_Mutation,
    );
    const { submit, submitting, open, setOpen } = useRootActionCommand(setManualAssignmentsMutation, {
        onSuccess: onSuccess,
        successMessage: 'Production plan manual assignments updated!',
    });

    const { data: labsData } = useListLabOrgsWithoutRelations({ skip: !open });

    const [isExternalDesign, setIsExternalDesign] = React.useState<boolean | null>(null);
    const [manufacturerId, setManufacturerId] = React.useState<string | null>(null);

    const labOptions = _.sortBy(
        labsData?.listOrganizations.map(lab => ({ value: lab.id, label: lab.name })) ?? [],
        o => o.label,
    );

    const canSubmit = !!((!isExternalDesign && !manufacturerId) || (isExternalDesign !== null && manufacturerId));

    const resetState = () => {
        setIsExternalDesign(null);
        setManufacturerId(null);
    };

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={(value: boolean) => {
                setOpen(value);

                if (!value) {
                    resetState();
                }
            }}
            title={'Manually Override Assignments'}
            content={
                <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                        <Text variant={'body1'} style={{ marginBottom: 8 }}>
                            Set Design Organization
                        </Text>
                        <SimpleSelect
                            label={'Design Organization'}
                            options={[NO_OVERRIDE_OPTION, INTERNAL_DESIGN_OPTION, EXTERNAL_DESIGN_OPTION]}
                            value={
                                // eslint-disable-next-line no-nested-ternary
                                isExternalDesign === null
                                    ? NO_OVERRIDE_VALUE
                                    : isExternalDesign
                                      ? EXTERNAL_DESIGN_VALUE
                                      : INTERNAL_DESIGN_VALUE
                            }
                            onChange={value => {
                                if (!value || value === NO_OVERRIDE_VALUE) {
                                    setIsExternalDesign(null);
                                    setManufacturerId(null);
                                } else {
                                    setIsExternalDesign(value === EXTERNAL_DESIGN_VALUE);
                                }
                            }}
                        />
                    </Grid>
                    {isExternalDesign !== null && (
                        <Grid item>
                            <Text variant={'body1'} style={{ marginBottom: 8 }}>
                                Set Manufacturing Organization{!isExternalDesign ? ' (Optional)' : ''}
                            </Text>
                            <SimpleSelect
                                label={'Manufacturing Organization'}
                                options={_.compact([!isExternalDesign ? NO_OVERRIDE_OPTION : undefined, ...labOptions])}
                                value={manufacturerId === null ? NO_OVERRIDE_VALUE : manufacturerId}
                                onChange={value => {
                                    setManufacturerId(!value || value === NO_OVERRIDE_VALUE ? null : value);
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item style={{ display: 'flex', justifyContent: 'right' }}>
                        <Button
                            variant={'primary'}
                            disabled={!canSubmit}
                            onClick={async () => {
                                if (!canSubmit) {
                                    return;
                                }
                                await submit({
                                    args: {
                                        id: productionOrderId,
                                        designOrganizationId:
                                            // eslint-disable-next-line no-nested-ternary
                                            isExternalDesign === null
                                                ? null
                                                : isExternalDesign
                                                  ? manufacturerId
                                                  : DANDY_INTERNAL_ID,
                                        manufacturingOrganizationId: isExternalDesign !== null ? manufacturerId : null,
                                    },
                                });
                                resetState();
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            }
            buttonText={'Manually Override Assignments'}
            buttonProps={{ variant: 'secondary' }}
        />
    );
};
