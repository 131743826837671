import { useViewManager } from '../ScanReview.hooks';
import type { ScanReviewRecordFactory, ScanReviewRecord } from '../ScanReviewRecordTypes';
import {
    type ScanReviewViewState,
    ScanReviewPanelType,
    type ScanReviewThreeViewProvider,
} from '../ScanReviewViewTypes';
import { prototypeToothPlacementFunction } from './ToothPlacementVisualization.utils';
import {
    type ToothPlacementVisualizationJawRecordFactory,
    ToothPlacementVisualization,
} from './ToothPlacementVisualizationTypes';
import type { ToothNumber } from '@orthly/items';
import constate from 'constate';
import React from 'react';
import * as THREE from 'three';

interface ToothPlacementVisualizationViewAppProps {
    lowerJawFactory: ToothPlacementVisualizationJawRecordFactory | null;
    upperJawFactory: ToothPlacementVisualizationJawRecordFactory | null;
    crownFactory: ScanReviewRecordFactory | null;
    toothNumber: ToothNumber;
    viewState: ScanReviewViewState;
}

export class ToothPlacementVisualizationPartialScene implements ScanReviewThreeViewProvider {
    scene: THREE.Scene;

    constructor(
        public upperJaw: ScanReviewRecord | null,
        public lowerJaw: ScanReviewRecord | null,
        public crown: ScanReviewRecord | null,
    ) {
        this.scene = new THREE.Scene();
        if (upperJaw) {
            this.scene.add(upperJaw.scanMesh);
        }
        if (lowerJaw) {
            this.scene.add(lowerJaw.scanMesh);
        }
        if (crown) {
            this.scene.add(crown.scanMesh);
        }
    }

    setUpperJawVisibility(visible: boolean) {
        this.upperJaw?.setVisible(visible);
    }

    setLowerJawVisibility(visible: boolean) {
        this.lowerJaw?.setVisible(visible);
    }
}

function useToothPlacementVisualizationViewApp({
    lowerJawFactory,
    upperJawFactory,
    crownFactory,
    toothNumber,
    viewState,
}: ToothPlacementVisualizationViewAppProps) {
    const scene = React.useMemo(() => {
        const upperJaw = upperJawFactory?.() ?? null;
        const lowerJaw = lowerJawFactory?.() ?? null;
        const crown = crownFactory?.() ?? null;

        const scene = new ToothPlacementVisualizationPartialScene(upperJaw, lowerJaw, crown);

        if (scene.crown) {
            prototypeToothPlacementFunction(toothNumber, scene.crown.scanMesh, upperJaw, lowerJaw);
        }
        return scene;
    }, [crownFactory, lowerJawFactory, toothNumber, upperJawFactory]);

    const viewManager = useViewManager(scene, ScanReviewPanelType.Isolated, viewState);

    const picker = React.useMemo(() => {
        return new ToothPlacementVisualization(scene, viewManager);
    }, [scene, viewManager]);

    return {
        picker,
    };
}

export const [ToothPlacementVisualizationViewAppProvider, useToothPlacementVisualizationViewContext] = constate(
    useToothPlacementVisualizationViewApp,
);
