import { useOpenOrderDetailOps } from '../../../../../../utils/router/useOpenOrderDetail';
import { OrderDetailPageFrame } from '../../OrderDetailPageFrame.graphql';
import { OrderDetailPageSidebar } from '../../OrderDetailPageSidebar';
import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { useLabtracDetailsSidebarParts } from '../LabtracDetailsSidebar/LabtracDetailsSidebar';
import { OrderDetailAlerts } from '../OrderDetailAlerts/OrderDetailAlerts';
import { useOrderChatSidebarParts } from '../OrderDetailChat/OrderDetailChat';
import { OrderDetailReviewBlock } from '../OrderDetailReviews/OrderDetailReviewBlock.graphql';
import { OrderDetailSummary } from '../OrderDetailSummary/OrderDetailSummary.graphql';
import { OrderDetailStatusActions } from '../OrderDetailToolbar/OrderDetailToolbarActions/OrderDetailStatusActions';
import { OrderDetailToolbarCopyId } from '../OrderDetailToolbar/OrderDetailToolbarCopyId';
import { OrderDetailToolbarDesignPreppedPill } from '../OrderDetailToolbar/OrderDetailToolbarDesignPreppedPill';
import { OrderDetailToolbarFiles } from '../OrderDetailToolbar/OrderDetailToolbarFiles';
import { OrderDetailToolbarRequestRush } from '../OrderDetailToolbar/OrderDetailToolbarRequestRush';
import { useOrderQcSidebarParts } from '../OrderQcSidebar/OrderQcSidebar';
import { AdminOrderDetailRefabBlock } from './AdminOrderDetailRefabBlock';
import { OrderAutoAssignmentDebuggerBlock } from './OrderAutoAssignmentDebuggerBlock';
import { OrderDetailAbutmentPartsCalculatorBlock } from './OrderDetailAbutmentPartsCalculatorBlock.graphql';
import { OrderDetailBillingBlock } from './OrderDetailBillingBlock';
import { OrderDetailChairsideRecordingsBlock } from './OrderDetailChairsideRecordingsBlock.graphql';
import { OrderDetailDevTools } from './OrderDetailDevTools';
import { OrderDetailItems } from './OrderDetailItems.graphql';
import { OrderDetailPreviousTryInFeedback } from './OrderDetailPreviousTryInFeedback.graphql';
import { OrderDetailSKU } from './OrderDetailSKU';
import { OrderDetailSplitInfoBlock } from './OrderDetailSplitInfoBlock.graphql';
import { OrderDetailTrackingBlock } from './OrderDetailTrackingBlock.graphql';
import { OrderSlaDebuggerBlock } from './OrderSlaDebuggerBlock';
import { OrderTaskTimelineBlock } from './OrderTaskTimelineBlock';
import { ProductionPlanPreviewBlock } from './ProductionPlanPreviewBlock';
import { SoftSmileAssociationBlock } from './SoftSmileAssociationBlock';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { useGetActiveInternalDesignTaskQuery } from '@orthly/graphql-react';
import { LabsGqlAlignerDesignSoftware } from '@orthly/graphql-schema';
import { OrthlyErrorBoundary } from '@orthly/ui';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import {
    OrderDesign,
    OrderDetailDoctorPhotosBlock,
    OrderDetailShadeMatchBlock,
    OrderWaxup,
    shouldShowWaxup,
    OrderDetailOriginalPhotosBlock,
    useFeatureFlag,
    OrderDetailTryInFeedback,
    NotesSummaryBlock,
    OrderDetailScanExportModelViewerBlockConnected,
    OrderLinkedOrdersBanners,
    OrderDetailLegacyMultiFulfillmentInfo,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({ root: { padding: '24px 12px' } }));

const AdminOrderDetailBodyRootLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailBodyRootLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        ...VeneerOrderLinkedOrdersBannersLabSalesOrder_Fragment
        ...AdminOrderDetailSummaryLabSalesOrder_Fragment
        ...AdminOrderDetailTrackingBlockSalesOrder_Fragment
        ...AdminOrderDetailPreviousTryInFeedbackLabSalesOrder_Fragment
        ...VeneerOrderDetailLegacyMultiFulfillmentInfoLabSalesOrder_Fragment
        ...AdminOrderDetailItemsLabSalesOrder_Fragment
        ...AdminOrderDetailSplitInfoBlockLabSalesOrder_Fragment
        ...VeneerOrderDetailScanExportModelViewerBlockConnectedLabSalesOrder_Fragment
        ...AdminOrderDetailReviewBlockLabSalesOrder_Fragment
        ...AdminOrderDetailChairsideRecordingsBlockLabSalesOrder_Fragment
        ...AdminOrderDetailAbutmentPartsCalculatorBlockLabSalesOrder_Fragment
    }
`);

const OrderDetailBodyRoot: React.VFC = () => {
    const classes = useStyles();
    const openOrder = useOpenOrderDetailOps();
    const { order, salesOrder: salesOrderRaw, refetch: refetchOrder, orderTrackerEntries } = useOrderDetailContext();
    const salesOrder = getFragmentData(AdminOrderDetailBodyRootLabSalesOrder_Fragment, salesOrderRaw);

    const { data: activeDesignTaskResult } = useGetActiveInternalDesignTaskQuery({
        variables: {
            orderId: order?.id ?? '',
        },
        skip: !order,
    });

    if (!order || !salesOrder) {
        return null;
    }

    const activeInternalDesignTask = activeDesignTaskResult?.getActiveInternalDesignTask ?? null;

    const imageUrls = order.scan_export.image_urls;

    const showWaxup = shouldShowWaxup(order);

    const { aligner_case } = order;

    return (
        <Grid container key={salesOrder.id}>
            <OrderLinkedOrdersBanners salesOrder={salesOrder} openOrder={openOrder} />
            <Grid container className={classes.root}>
                <OrderDetailAlerts order={order} refetchOrder={refetchOrder} />
                <AdminOrderDetailRefabBlock order={order} refetchOrder={refetchOrder} />
                <Grid container item xs={5}>
                    <OrderDetailSummary
                        salesOrder={salesOrder}
                        labOrder={order}
                        activeInternalDesignTask={activeInternalDesignTask}
                        refetch={refetchOrder}
                    />
                </Grid>
                <Grid container item xs={7}>
                    <OrderDetailTrackingBlock
                        order={order}
                        salesOrderFragment={salesOrder}
                        refetchOrder={refetchOrder}
                        orderTrackerEntries={orderTrackerEntries}
                    />
                </Grid>
                <OrderDetailPreviousTryInFeedback salesOrder={salesOrder} />
                <OrderDetailSKU order={order} refetchOrder={refetchOrder} />
                <OrderDetailLegacyMultiFulfillmentInfo salesOrder={salesOrder} />
                <NotesSummaryBlock order={order} refetchOrder={refetchOrder} />
                <OrderDetailItems
                    salesOrder={salesOrder}
                    refetch={refetchOrder}
                    labOrder={order}
                    openOrder={openOrder}
                />
                <OrderDetailSplitInfoBlock salesOrder={salesOrder} openOrder={openOrder} />
                <OrderDetailBillingBlock labOrder={order} refetch={refetchOrder} />
                <OrderDetailScanExportModelViewerBlockConnected salesOrder={salesOrder} />
                <OrderDetailReviewBlock salesOrder={salesOrder} />
                {!aligner_case && showWaxup && (
                    <OrderWaxup order={order} refetchOrder={refetchOrder} userRole={'psr'} clickToLoad={true} />
                )}
                {!aligner_case && !showWaxup && !!order.design_file_path && (
                    <OrderDesign order={order} userRole={'psr'} clickToLoad />
                )}
                <Grid item container direction={`column`}>
                    {!aligner_case && imageUrls && (
                        <Grid item style={{ display: `flex`, flexBasis: 0, flexGrow: 1 }}>
                            <OrderDetailShadeMatchBlock
                                orderId={order.id}
                                imageUrls={imageUrls}
                                refetchOrder={refetchOrder}
                            />
                        </Grid>
                    )}
                    <OrderDetailDoctorPhotosBlock order={order} refetchOrder={refetchOrder} isPSR={true} />
                    <OrderDetailOriginalPhotosBlock order={order} refetchOrder={refetchOrder} isPSR={true} />
                </Grid>
                <OrderDetailTryInFeedback orderId={salesOrder.id} isPreviousOrder={false} accordion={true} />
                <OrderDetailChairsideRecordingsBlock salesOrder={salesOrder} />
                <OrderTaskTimelineBlock order={order} />
                <OrderAutoAssignmentDebuggerBlock order={order} />
                <OrderDetailAbutmentPartsCalculatorBlock salesOrder={salesOrder} />
                <OrderSlaDebuggerBlock order={order} />
                {order.aligner_case?.design_software === LabsGqlAlignerDesignSoftware.SoftSmile && (
                    <SoftSmileAssociationBlock order={order} refetchOrder={refetchOrder} />
                )}
                <OrderDetailDevTools />
                <ProductionPlanPreviewBlock orderId={salesOrder.id} />
            </Grid>
        </Grid>
    );
};

const OrderDetailChat: React.FC = () => {
    const chatSidebarParts = useOrderChatSidebarParts();
    const qcSidebarParts = useOrderQcSidebarParts();
    const labtracSidebarParts = useLabtracDetailsSidebarParts();
    const { value: enableLabtracInfoTab } = useFeatureFlag('enableLabtracInfoTab');
    return (
        <OrderDetailPageSidebar
            tabs={_.compact([chatSidebarParts, qcSidebarParts, enableLabtracInfoTab && labtracSidebarParts])}
        />
    );
};

export const OrderDetailDefaultTabPage: React.FC = () => {
    return (
        <OrderDetailPageFrame
            body={<OrderDetailBodyRoot />}
            sidebar={
                <OrthlyErrorBoundary componentName={'OrderDetailChat'}>
                    <OrderDetailChat />
                </OrthlyErrorBoundary>
            }
            toolbarActions={
                <>
                    <OrderDetailToolbarRequestRush />
                    <OrderDetailToolbarCopyId />
                    <OrderDetailToolbarFiles />
                    <OrderDetailToolbarDesignPreppedPill />
                    <OrderDetailStatusActions />
                </>
            }
        />
    );
};
