import { trackGuidedWaxupButtonClick } from './DesignViewerLiteDetachedContainer';
import { useDesignViewerButtonStyles } from './GuidedWaxups/VisualConstants.util';
import { ColorToggleButton } from './GuidedWaxups/components/ColorToggleButton';
import { DesignViewLiteButton } from './GuidedWaxups/components/DesignViewerLiteButton';
import { MarginToggleButton } from './GuidedWaxups/components/MarginToggleButton';
import { TransparencyControls } from './GuidedWaxups/components/TransparencyControls';
import { TransparencyToggleMobile } from './GuidedWaxups/components/TransparencyToggleMobile';
import { useGuidedWaxupContext } from './GuidedWaxups/state/GuidedWaxupContext';
import type { ExternalAppearanceApp, ModelAppearance, QcHeatmapRange } from '@orthly/dentin';
import {
    HeatMapSelectionBar,
    HeatMapTypeToLabelMap,
    QCColorLegend,
    RestorativeView,
    orderMaterialCanHaveLayers,
    useToggleMarginLines,
    useToggleTransparencyPreExtractionScans,
} from '@orthly/dentin';
import { ButtonGroup, Grid, QCHeatmapIcon, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export type ButtonGroupState = 'anatomyAndTransparencySelection' | 'mobileHeatmapBar' | 'desktopHeatmapBar' | 'default';

export const DesignViewerLiteButtonGroups: React.VFC<{
    externalAppearanceApp: ExternalAppearanceApp;
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    dynamicHeatmaps: boolean;
}> = ({ externalAppearanceApp, appearance, setAppearance, dynamicHeatmaps }) => {
    const classes = useDesignViewerButtonStyles();
    const { order, isImmediateDenture, isRemoveable } = useGuidedWaxupContext();
    const canHaveLayers = orderMaterialCanHaveLayers(order);
    const isMobileOrTablet = useScreenIsMobileOrVerticalTablet();
    const buttonGroupRef = React.useRef<HTMLDivElement | null>(null);
    const [hideButtonGroup, setHideButtonGroup] = React.useState<boolean>(false);
    const [heatmapAnchorEl, setHeatmapAnchorEl] = React.useState<HTMLElement | null>(null);
    const [transparencyToggleOpen, setTransparencyToggleOpen] = React.useState<boolean>(false);

    //Scans Transparency
    const onToggleTransparencyPreExtractionScans = useToggleTransparencyPreExtractionScans(setAppearance);

    // Margins
    const onToggleMarginLines = useToggleMarginLines(setAppearance);

    // Heatmap
    const heatmapActive = appearance.restorativeView === RestorativeView.HeatMap;

    const closeTransparencyMenu = React.useCallback(() => {
        setHideButtonGroup(false);
        setTransparencyToggleOpen(false);
    }, []);

    const resetHeatmapState = React.useCallback(
        (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            e?.stopPropagation();
            setAppearance(appearance => ({
                ...appearance,
                restorativeView: RestorativeView.CAD,
                showAnatomyLayers: false,
            }));
            setHeatmapAnchorEl(null);
        },
        [setAppearance],
    );

    const heatmapButtonClick = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setHeatmapAnchorEl(heatmapAnchorEl ? null : e.currentTarget);
            trackGuidedWaxupButtonClick('Toggle Heatmap Selector');
            // if we open the heatmap selection bar while anatomy/transparency selection bar is open,
            // close the anatomy/transparency selection bar and toggle off anatomy/transparency
            closeTransparencyMenu();
            !heatmapActive &&
                setAppearance(appearance => ({
                    ...appearance,
                    showAnatomyLayers: false,
                }));
        },
        [heatmapAnchorEl, heatmapActive, setAppearance, closeTransparencyMenu],
    );

    const transparencyButtonClick = React.useCallback(() => {
        setTransparencyToggleOpen(current => !current);
        // if items in order can have layers, then we open a selection bar where doctor can
        // toggle transparency and anatomy layers on.
        if (canHaveLayers) {
            // if we open the anatomy/transparency selection bar while heatmap selection bar is open,
            // close the heatmap selection bar and disable any active heatmap
            resetHeatmapState();
        } else {
            // if no layers, this button simply acts as the toggle for transparency on the restorations
            trackGuidedWaxupButtonClick('Toggle Transparency Restoration');
            onToggleTransparencyPreExtractionScans();
        }
    }, [canHaveLayers, resetHeatmapState, onToggleTransparencyPreExtractionScans]);

    const onButtonGroupToggle = React.useCallback(() => {
        setHideButtonGroup(current => !current);
    }, []);
    // This effect is necessary for smaller mobile devices where the general view control buttons are pushed below
    // the fold when the heatmap or anatomy/transparency selection bars are opened. It automatically scrolls to keep the
    // toolbar in view and accessible
    React.useEffect(() => {
        buttonGroupRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [heatmapAnchorEl, buttonGroupRef]);

    const handleHeatMapRangeChange = React.useCallback(
        (newValues: QcHeatmapRange): void => {
            setAppearance(current => ({
                ...current,
                heatMapRange: dynamicHeatmaps ? newValues : undefined,
            }));
        },
        [setAppearance, dynamicHeatmaps],
    );

    const showHeatmaps = !isImmediateDenture;
    const enableProximalHeatmap = !isRemoveable;
    return (
        <div>
            {isMobileOrTablet && heatmapActive && (
                <QCColorLegend
                    dynamicHeatmaps={dynamicHeatmaps}
                    heatMapType={appearance.activeHeatMap}
                    heatMapRange={appearance.heatMapRange}
                    horizontal
                    setHeatMapRange={handleHeatMapRangeChange}
                    hideZoom
                />
            )}
            <div
                ref={buttonGroupRef}
                className={hideButtonGroup ? classes.hidden : classes.buttonGroupContainer}
                style={{ width: '100%', display: 'flex' }}
            >
                <ButtonGroup className={classes.buttonGroup} style={{ width: '100%', display: 'flex' }}>
                    <ColorToggleButton externalAppearanceApp={externalAppearanceApp} />
                    <MarginToggleButton appearance={appearance} onToggleMarginLines={onToggleMarginLines} />
                    <TransparencyControls
                        appearanceSettings={appearance}
                        onAppearanceChange={setAppearance}
                        canHaveLayers={canHaveLayers}
                        onClick={transparencyButtonClick}
                        onClose={() => setTransparencyToggleOpen(false)}
                    />
                    {showHeatmaps && (
                        <DesignViewLiteButton
                            icon={<QCHeatmapIcon />}
                            label={'Heatmap'}
                            onClick={heatmapButtonClick}
                            onClose={resetHeatmapState}
                            isActive={!!heatmapAnchorEl}
                            extraLabel={heatmapActive ? HeatMapTypeToLabelMap[appearance.activeHeatMap] : undefined}
                        />
                    )}
                </ButtonGroup>

                {!isMobileOrTablet && heatmapAnchorEl && (
                    <div className={classes.desktopHeatmapMenu}>
                        <Grid container>
                            <Grid item>
                                <HeatMapSelectionBar
                                    appearanceSettings={appearance}
                                    onAppearanceChange={setAppearance}
                                    showBasicHeatmaps={true}
                                    enableSculptReviewHeatmaps={false}
                                    enableUndercutHeatmap={false}
                                    enableTissuePressureHeatmap={false}
                                    enableGuidedWaxupToolbar={true}
                                    enableProximalHeatmap={enableProximalHeatmap}
                                    anchorEl={heatmapAnchorEl}
                                    onClose={() => setHeatmapAnchorEl(null)}
                                    dynamicHeatmaps={dynamicHeatmaps}
                                />
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>

            {isMobileOrTablet && transparencyToggleOpen && (
                <TransparencyToggleMobile
                    appearanceSettings={appearance}
                    onAppearanceChange={setAppearance}
                    onFormToggle={onButtonGroupToggle}
                    canHaveLayers={canHaveLayers}
                />
            )}
            {isMobileOrTablet && heatmapAnchorEl && (
                <Grid container justifyContent={'flex-end'}>
                    <HeatMapSelectionBar
                        appearanceSettings={appearance}
                        onAppearanceChange={setAppearance}
                        showBasicHeatmaps={true}
                        enableSculptReviewHeatmaps={false}
                        enableUndercutHeatmap={false}
                        enableTissuePressureHeatmap={false}
                        enableProximalHeatmap={enableProximalHeatmap}
                        enableGuidedWaxupToolbar={true}
                        onClose={() => setHeatmapAnchorEl(null)}
                        dynamicHeatmaps={dynamicHeatmaps}
                    />
                </Grid>
            )}
        </div>
    );
};
