import type { QcHeatmapRange } from '../ColorRamp';
import type { ScanReviewEditedMarginLine, ScanReviewMarginLineEditor } from './MarginMarking';
import type { ScanReviewRecord, ScanReviewRecordsFactory } from './ScanReviewRecordTypes';
import { ScanReviewDisplayType, ScanReviewPanelType, type ScanReviewThreeViewProvider } from './ScanReviewViewTypes';
import { type ToothNumber } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import * as THREE from 'three';

export interface ScanReviewScene {
    setUpperJawVisibility(visible: boolean): void;
    setLowerJawVisibility(visible: boolean): void;
    setDisplayType(displayType: ScanReviewDisplayType, heatmapRange: QcHeatmapRange | null): void;
    setStoneModelDisplay(): void;
    setScanDisplay(): void;
    setUndercutDisplay(): void;
    setStoneUndercutDisplay(): void;
    updateUndercutDisplay(jaw: Jaw, insertionAxis: THREE.Vector3): void;
    setHeatMapDisplay(): void;
    updateHeatmapRange(newHeatmapRange: QcHeatmapRange): void;
    updateUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange): void;
    get upperJawInScene(): boolean;
    get lowerJawInScene(): boolean;
}
export class ScanReviewPartialScene implements ScanReviewScene, ScanReviewThreeViewProvider {
    scene: THREE.Scene;

    constructor(
        public upperJaw: ScanReviewRecord | null,
        public lowerJaw: ScanReviewRecord | null,
    ) {
        this.scene = new THREE.Scene();
        if (upperJaw) {
            this.scene.add(upperJaw.scanMesh);
        }
        if (lowerJaw) {
            this.scene.add(lowerJaw.scanMesh);
        }
    }

    get upperJawInScene(): boolean {
        return this.upperJaw !== null;
    }
    get lowerJawInScene(): boolean {
        return this.lowerJaw !== null;
    }

    setDisplayType(displayType: ScanReviewDisplayType, heatmapRange: QcHeatmapRange | null): void {
        if (displayType === ScanReviewDisplayType.Scan) {
            this.setScanDisplay();
        } else if (displayType === ScanReviewDisplayType.StoneModel) {
            this.setStoneModelDisplay();
        } else if (displayType === ScanReviewDisplayType.ScanUndercut) {
            this.setUndercutDisplay();
        } else if (displayType === ScanReviewDisplayType.StoneModelUndercut) {
            this.setStoneUndercutDisplay();
        } else if (displayType === ScanReviewDisplayType.BiteAnalysis) {
            this.setHeatMapDisplay();
            if (heatmapRange) {
                this.updateHeatmapRange(heatmapRange);
            }
        }
    }

    setUpperJawVisibility(visible: boolean) {
        this.upperJaw?.setVisible(visible);
    }

    setLowerJawVisibility(visible: boolean) {
        this.lowerJaw?.setVisible(visible);
    }

    setStoneModelDisplay() {
        this.upperJaw?.setStoneModelDisplay();
        this.lowerJaw?.setStoneModelDisplay();
    }

    setUndercutDisplay() {
        this.upperJaw?.setUndercutDisplay();
        this.lowerJaw?.setUndercutDisplay();
    }

    setStoneUndercutDisplay() {
        this.upperJaw?.setStoneUndercutDisplay();
        this.lowerJaw?.setStoneUndercutDisplay();
    }

    updateUndercutDisplay(jaw: Jaw, insertionAxis: THREE.Vector3) {
        if (jaw === Jaw.UPPER) {
            this.upperJaw?.updateUndercutDisplay(insertionAxis);
        } else {
            this.lowerJaw?.updateUndercutDisplay(insertionAxis);
        }
    }

    setScanDisplay(): void {
        this.upperJaw?.setScanDisplay();
        this.lowerJaw?.setScanDisplay();
    }

    setHeatMapDisplay() {
        this.upperJaw?.setHeatMapDisplay();
        this.lowerJaw?.setHeatMapDisplay();
    }

    updateHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        this.upperJaw?.updateHeatmapRange(newHeatmapRange);
        this.lowerJaw?.updateHeatmapRange(newHeatmapRange);
    }

    updateUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        this.upperJaw?.updateUndercutHeatmapRange?.(newHeatmapRange);
        this.lowerJaw?.updateUndercutHeatmapRange?.(newHeatmapRange);
    }
}

export interface ScanReviewActiveJawData {
    jaw: Jaw;
    scanMesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>;
    insertionAxis: THREE.Vector3;
}

export interface ScanReviewSceneState {
    toothNumber?: ToothNumber;
    upperJawInsertionAxis: THREE.Vector3;
    lowerJawInsertionAxis: THREE.Vector3;
    preppedTeeth: Map<ToothNumber, boolean>;
    currentMarginLine?: ScanReviewEditedMarginLine;
    currentMarginLineEditor?: ScanReviewMarginLineEditor;
}

export class ScanReviewSceneStateManager {
    private toothNumber?: ToothNumber;
    private upperJawInsertionAxis: THREE.Vector3;
    private lowerJawInsertionAxis: THREE.Vector3;
    private preppedTeeth: Map<ToothNumber, boolean>;

    constructor(
        currentState: ScanReviewSceneState,
        private readonly setSceneState: (state: ScanReviewSceneState) => void,
        private readonly marginLineEditors: Map<ToothNumber, ScanReviewMarginLineEditor>,
    ) {
        this.toothNumber = currentState.toothNumber;
        this.upperJawInsertionAxis = currentState.upperJawInsertionAxis;
        this.lowerJawInsertionAxis = currentState.lowerJawInsertionAxis;
        this.preppedTeeth = currentState.preppedTeeth;
    }

    private updateSceneState() {
        this.setSceneState({
            toothNumber: this.toothNumber,
            preppedTeeth: this.preppedTeeth,
            upperJawInsertionAxis: this.upperJawInsertionAxis,
            lowerJawInsertionAxis: this.lowerJawInsertionAxis,
            currentMarginLine: this.toothNumber
                ? this.marginLineEditors.get(this.toothNumber)?.currentEditedMarginLine
                : undefined,
            currentMarginLineEditor: this.toothNumber ? this.marginLineEditors.get(this.toothNumber) : undefined,
        });
    }

    setToothNumber(toothNumber?: ToothNumber) {
        this.toothNumber = toothNumber;
        this.updateSceneState();
    }

    setPreppedTeeth(preppedTeeth: Map<ToothNumber, boolean>) {
        this.preppedTeeth = preppedTeeth;
        this.updateSceneState();
    }

    setUpperJawInsertionAxis(axis: THREE.Vector3) {
        this.upperJawInsertionAxis = axis;
        this.updateSceneState();
    }

    setLowerJawInsertionAxis(axis: THREE.Vector3) {
        this.lowerJawInsertionAxis = axis;
        this.updateSceneState();
    }
}

export class ScanReviewCompositeScene implements ScanReviewScene {
    private readonly upperView: ScanReviewPartialScene;
    private readonly lowerView: ScanReviewPartialScene;
    private readonly leftView: ScanReviewPartialScene;
    private readonly rightView: ScanReviewPartialScene;
    private readonly frontView: ScanReviewPartialScene;

    constructor(recordsFactory: ScanReviewRecordsFactory) {
        this.upperView = new ScanReviewPartialScene(recordsFactory()?.upperJaw, null);
        this.lowerView = new ScanReviewPartialScene(null, recordsFactory()?.lowerJaw);
        this.leftView = new ScanReviewPartialScene(recordsFactory().upperJaw, recordsFactory()?.lowerJaw);
        this.rightView = new ScanReviewPartialScene(recordsFactory().upperJaw, recordsFactory()?.lowerJaw);
        this.frontView = new ScanReviewPartialScene(recordsFactory().upperJaw, recordsFactory()?.lowerJaw);
    }

    get upperJawInScene(): boolean {
        return this.frontView.upperJawInScene;
    }
    get lowerJawInScene(): boolean {
        return this.frontView.lowerJawInScene;
    }

    private *partialScenes() {
        for (const partialScene of [this.upperView, this.lowerView, this.leftView, this.rightView, this.frontView]) {
            yield partialScene;
        }
    }

    getPartialSceneForPanelType(panelType: ScanReviewPanelType): ScanReviewPartialScene {
        switch (panelType) {
            case ScanReviewPanelType.Upper: {
                return this.upperView;
            }
            case ScanReviewPanelType.Lower: {
                return this.lowerView;
            }
            case ScanReviewPanelType.Left: {
                return this.leftView;
            }
            case ScanReviewPanelType.Right: {
                return this.rightView;
            }
            case ScanReviewPanelType.Front: {
                return this.frontView;
            }
        }
    }

    setUpperJawVisibility(visible: boolean): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.setUpperJawVisibility(visible);
        }
    }
    setLowerJawVisibility(visible: boolean): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.setLowerJawVisibility(visible);
        }
    }

    setDisplayType(displayType: ScanReviewDisplayType, heatmapRange: QcHeatmapRange | null): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.setDisplayType(displayType, heatmapRange);
        }
    }

    setStoneModelDisplay() {
        for (const partialScene of this.partialScenes()) {
            partialScene.setStoneModelDisplay();
        }
    }
    setScanDisplay() {
        for (const partialScene of this.partialScenes()) {
            partialScene.setScanDisplay();
        }
    }
    setHeatMapDisplay() {
        for (const partialScene of this.partialScenes()) {
            partialScene.setHeatMapDisplay();
        }
    }

    setUndercutDisplay(): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.setUndercutDisplay();
        }
    }

    setStoneUndercutDisplay(): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.setStoneUndercutDisplay();
        }
    }

    updateUndercutDisplay(jaw: Jaw, insertionAxis: THREE.Vector3): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.updateUndercutDisplay(jaw, insertionAxis);
        }
    }

    updateHeatmapRange(newHeatmapRange: QcHeatmapRange): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.updateHeatmapRange(newHeatmapRange);
        }
    }

    updateUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange): void {
        for (const partialScene of this.partialScenes()) {
            partialScene.updateUndercutHeatmapRange(newHeatmapRange);
        }
    }
}
