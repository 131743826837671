import type { IndexedSphere } from '../../ModelViewer/Margin.util';
import type { ScanReviewMarginMarkingToolController } from './ScanReviewMarginMarkingToolController';
import { ScanReviewMarginMarkingToolActiveState } from './ScanReviewMarginMarkingToolTypes';

export abstract class ScanReviewMarginMarkingToolState {
    public constructor(protected controller: ScanReviewMarginMarkingToolController) {}
    enter(): void {}
    exit(): void {}
    handlePointerDown(_evt: MouseEvent): void {}
    handlePointerMove(_evt: MouseEvent): void {}
    handlePointerUp(_evt: MouseEvent): void {}
    handleKeyDown(_evt: KeyboardEvent): void {}
    handleKeyUp(_evt: KeyboardEvent): void {}
}

export class ScanReviewMarginMarkingOrbitingState extends ScanReviewMarginMarkingToolState {
    handlePointerUp(_evt: MouseEvent): void {
        return this.controller.switchState(ScanReviewMarginMarkingToolActiveState.Drawing);
    }
}

export class ScanReviewMarginMarkingTransformingState extends ScanReviewMarginMarkingToolState {
    private activeControlPoint: IndexedSphere | undefined;

    enter(): void {
        this.activeControlPoint = this.controller.getActiveHighlight();
        this.controller.updateFalloffMap(this.activeControlPoint);
        this.controller.beginTransformingControlPoint();
    }
    exit(): void {
        this.controller.finishTransformingControlPoint();
    }
    handlePointerMove(_evt: MouseEvent): void {
        if (!this.activeControlPoint) {
            return this.controller.switchState(ScanReviewMarginMarkingToolActiveState.Drawing);
        }
        this.controller.updateControlPointTransform(this.activeControlPoint);
    }
    handlePointerUp(_evt: MouseEvent): void {
        return this.controller.switchState(ScanReviewMarginMarkingToolActiveState.Drawing);
    }
}

export class ScanReviewMarginMarkingDrawingState extends ScanReviewMarginMarkingToolState {
    private pointerDown: boolean | undefined;
    private deleteToggle: boolean | undefined;

    enter(): void {
        this.controller.setCameraControlsEnabled(true);
        this.controller.updateMarginLine();
        this.controller.updatePreviewCircle();
        this.controller.updatePreviewLine();
    }
    handlePointerDown(_evt: MouseEvent): void {
        this.pointerDown = true;
        if (this.controller.isPointerOverControlPoint()) {
            this.controller.setCameraControlsEnabled(false);
            if (this.deleteToggle) {
                this.controller.deleteControlPoint();
                this.deleteToggle = false;
            } else {
                this.controller.addControlPointOrCloseLine();
            }
        } else if (!this.controller.isPointerOverControlPoint() && this.controller.isPointerOverMarginLine()) {
            this.controller.setCameraControlsEnabled(false);
            this.controller.insertControlPoint();
        } else if (this.controller.isMarginLineClosed() && this.controller.isPointerOverMesh()) {
            return this.controller.switchState(ScanReviewMarginMarkingToolActiveState.Orbiting);
        } else if (this.controller.isPointerOverMesh()) {
            this.controller.setCameraControlsEnabled(false);
            this.controller.addControlPointOrCloseLine();
        } else {
            return this.controller.switchState(ScanReviewMarginMarkingToolActiveState.Orbiting);
        }
    }
    handlePointerMove(_evt: MouseEvent): void {
        if (this.pointerDown && this.controller.isPointerOverControlPoint()) {
            return this.controller.switchState(ScanReviewMarginMarkingToolActiveState.Transforming);
        }
        this.controller.updatePreviewCircle();
        this.controller.updatePreviewLine();
        this.controller.updateActiveHighlight();
        this.controller.updateCompletionLine();
    }
    handlePointerUp(_evt: MouseEvent): void {
        this.pointerDown = false;
        this.controller.setCameraControlsEnabled(true);
    }
    handleKeyDown(evt: KeyboardEvent): void {
        if (evt.ctrlKey) {
            evt.preventDefault();
            this.deleteToggle = true;
        }
    }
    handleKeyUp(_evt: KeyboardEvent): void {
        this.deleteToggle = false;
    }
}
